import React, { Component, useRef, useEffect } from 'react';
import { Route, Link, Routes, useParams } from 'react-router-dom';
import '../sass/detail-page.scss';
import $, { param } from 'jquery';
import { SeoMeta } from './Layout/SeoMeta';
import Moment from 'react-moment';
import { Store } from 'react-notifications-component';
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import ReactGA from 'react-ga';

function Fancybox(props) {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        const delegate = props.delegate || "[data-fancybox]";
        const options = props.options || {};

        NativeFancybox.bind(container, delegate, options);

        return () => {
            NativeFancybox.unbind(container);
            NativeFancybox.close();
        };
    });

    return <div ref={containerRef}>{props.children}</div>;
}

function GetArtistId() {
    const params = useParams();
    return params.Id;
}

export class ArtistDetails extends Component {
    static displayName = ArtistDetails.name;

    constructor(props) {
        super(props);
        this.state = { artistItem: [], loading: true, loadingReview: true, bookingDate: '', isLike: false, artistId: 0, reviews: [], breadcrums: [], shareUrl: window.location.href, userData: null };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBookNow = this.handleBookNow.bind(this);
        this.handleLike = this.handleLike.bind(this);
        this.handleUsefull = this.handleUsefull.bind(this);
        this.state.breadcrums.push({ title: 'Home', url: '/' });
        this.state.breadcrums.push({ title: 'Artist', url: '/artist' });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async checkUserLoggedIn() {
        const response = await fetch('/v1/api/User', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' }
            /*body: JSON.stringify({				
            })*/
        });
        const data = await response.json();


        this.setState({ userData: data });
    }


    componentDidMount() {


       
        const script = document.createElement("script");
        script.src = "/js/sticky_sidebar.min.js";
        script.async = false;
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "/js/specific_detail.min.js";
        script2.async = false;
        document.body.appendChild(script2);

        const script3 = document.createElement("script");
        script3.src = "/js/datepicker.min.js";
        script3.async = false;
        document.body.appendChild(script3);

        const script4 = document.createElement("script");
        script4.src = "/js/datepicker_func_1.js";
        script4.async = false;
        document.body.appendChild(script4);

        const script10 = document.createElement("script");
        script10.src = "/js/modernizr.custom.js";
        script10.async = false;
        document.body.appendChild(script10);

            

      


        const script7 = document.createElement("script");
        script7.src = "/js/lightbox.js";
        script7.async = false;
        document.body.appendChild(script7);

        const script6 = document.createElement("script");
        script6.src = "/js/manosary_func.js";
        script6.async = false;
        document.body.appendChild(script6);

        let urlElements = window.location.href.split('/');
        var id = urlElements[urlElements.length - 1];
        
        this.populateArtistData(id);
        this.populateArtistsReview(id);
        this.checkUserLoggedIn();

    }


    async populateArtistData(id) {
        const response = await fetch('v1/api/ArtistById', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "Id": id
            })
        });
        const data = await response.json();
        if (data.status) {
            this.setState({ artistItem: data.data, loading: false, isLike: data.data.isLiked, artistId: id });

            this.state.breadcrums.push({ title: data.data.name, url: "/artist/" + data.data.name.replace(/ /g,'-') + "/" + data.data.id });

        } else {
            //
            this.setState({ artistItem: null, loading: false });
        }
    }

    handleBookNow(event) {


        ReactGA.event({
            category: 'BookNow',
            action: 'BookNow Clicked'
        });

        var date = $('#datepicker_field').val();

        var url = '/book/?artistId=' + this.state.artistItem.id + '&bookingDate=' + date;
        window.location.href = url;

       // alert(url);

        event.preventDefault();
    }

    async handleLike(event) {

        var isLike = this.state.isLike;

        const response = await fetch('v1/api/user/toggleWishlist', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "ArtistId": this.state.artistItem.id,
                "isLike": !isLike
            })
        });
        const data = await response.json();
        if (data.status) {

            ReactGA.event({
                category: 'Wishlist',
                action: 'New Wishlist Clicked'
            });

            this.setState({ isLike: data.data });
        } else {
            //

            if (data.statusMessage == "LoginRequired") {

                Store.addNotification({
                    title: "Please login!",
                    message: "Redirecting to login page..",
                    type: "warning",
                    insert: "top",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    },
                    onRemoval: (id, removedBy) => {
                        window.location.href = '/login';
                    }
                });
            }
        }

        event.preventDefault();
    }

    async handleUsefull(event, item, idx, isUp) {

        

        const response = await fetch('v1/api/review/toggleUseFull', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "Id": item.id,
                "NotUseFullCount": (isUp == 1 ? 0 : 1),
                "UseFullCount": (isUp == 1 ? 1 : 0)               
            })
        });
        const data = await response.json();
        if (data.status) {
            //this.setState({ isLike: data.data });
            this.state.reviews[idx] = data.data;
            this.setState({ reviews: this.state.reviews, loadingReview: false });
        } else {
            
            if (data.statusMessage == "LoginRequired") {
                
                Store.addNotification({
                    title: "Please login!",
                    message: "Redirecting to login page..",
                    type: "warning",
                    insert: "top",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    },
                    onRemoval: (id, removedBy) => {
                        window.location.href = '/login';
                    }
                });
            }
        }

        event.preventDefault();
    }


    async populateArtistsReview(id) {
        const response = await fetch('v1/api/Artist/Review?artistId=' + id, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        this.setState({ reviews: data.data, loadingReview: false });



    }

    htmlDecode(content) {
        let e = document.createElement('div');
        e.innerHTML = content;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    renderReviewtItems(dataItem) {

        if ((dataItem != null && dataItem.length == 0) || dataItem == null) {
            return (
                <><p>No customer reviews</p></>
            );
        } else {

            return dataItem?.map((item, idx) => { // here
                return (
                    <>

                        <div class="review_card">
                            <div class="row">
                                <div class="col-md-2 user_info">
                                    <figure><img src="img/avatar4.jpg" alt="" /></figure>
                                    <h5>{item.UserName}</h5>
                                </div>
                                <div class="col-md-10 review_content">
                                    <div class="">
                                        <em>Published <Moment date={item.createdDate} fromNow /></em>
                                    </div>
                                    <h4>"{item.reviewTitle}"</h4>
                                    <p>{item.reviewDescription}</p>
                                    <ul>
                                        <li><a href="javacript:void(0)" onClick={(event) => this.handleUsefull(event, item, idx, 1)} class={item.useFullCount > 0 ? 'bg-success text-light' : ''}><i class="icon_like"></i><span>Useful</span>  <span class="badge bg-gradient">{item.useFullCount > 0 ? item.useFullCount : ''}</span></a></li>
                                        <li><a href="javacript:void(0)" onClick={(event) => this.handleUsefull(event, item, idx, 0)} class={item.notUseFullCount > 0 ? 'bg-warning text-light' : ''}><i class="icon_dislike"></i><span>Not useful</span><span class="badge bg-gradient">{item.notUseFullCount > 0 ? item.notUseFullCount : ''}</span></a></li>

                                    </ul>
                                </div>
                            </div>

                        </div>


                    </>
                );
            });
        }
    }

    renderAritstReview(data) {


        if (data != null) {

            return (
                <div class="box_general">
                    <div class="tabs_detail">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a id="tab-B" href="#pane-B" class="nav-link active" data-bs-toggle="tab" role="tab">Reviews</a>
                            </li>
                        </ul>
                        <div class="tab-content" role="tablist">


                            <div id="pane-B" class="card tab-pane  fade show active" role="tabpanel" aria-labelledby="tab-B">
                                <div class="card-header" role="tab" id="heading-B">
                                    <h5>
                                        <a class="collapsed" data-bs-toggle="collapse" href="#collapse-B" aria-expanded="false" aria-controls="collapse-B">
                                            Reviews
                                        </a>
                                    </h5>
                                </div>
                                <div id="collapse-B" class="collapse" role="tabpanel" aria-labelledby="heading-B">
                                    <div class="card-body reviews">
                                       
                                        <div id="reviews">                                        
                                            {this.renderReviewtItems(data)}
                                        </div>                                      
                                        <p class="text-end">{this.state.userData?.status ? <><a href={'/review/?artistId=' + this.state.artistId} class="btn_1">Leave a review</a> </> : <> <a href='/login' class="btn btn-light">Login to review</a></>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            );
        }
    }

    renderBreadcrumItems(data) {

        return data?.map((item, idx) => { // here
            return (
                <>
                    <li><a href={item.url}>{item.title}</a></li>
                </>
            );
        });
    }

    renderBreadcrums(data) {

        return (
            <ul class='float-end'>{this.renderBreadcrumItems(data)}</ul>
        );
    }


    render() {
        const artistItem = this.state.artistItem;
        const photoGalObj = artistItem.photoImageGallery != null ? JSON.parse(artistItem.photoImageGallery) : null;
        const videoGalObj = artistItem.videoGallery != null ? JSON.parse(artistItem.videoGallery) : null;
        const reviews = this.state.loadingReview == false ? this.renderAritstReview(this.state.reviews) : '';
        const breadCrums = this.state.breadcrums != null ? this.renderBreadcrums(this.state.breadcrums) : '';


        return (
            <>
                <SeoMeta pageType={this.props.pageType} prefix={artistItem.name} artist={artistItem} breadcrums={this.state.breadcrums} />
                <div class="container margin_detail">
                    <div class="row">
                        <div class="col-xl-8 col-lg-7 breadcrumbs">
                            {breadCrums}
                        </div>
                        <div class="col-xl-8 col-lg-7">
                           
                            <div class="box_general ">
                             
                                <div class='artist_img'>
                                    <img src={artistItem.banner} alt={artistItem.name} class="img-fluid" />
                                </div>
                                <div class="main_info_wrapper">
                                    <div class="main_info clearfix">
                                        <div class="user_thumb">
                                            <figure><img src={artistItem.profileImage} alt={artistItem.name} /></figure>

                                        </div>
                                        <div class="user_desc">
                                            <h3>{artistItem.name}</h3>
                                            <p>{artistItem.city}, {artistItem.country} </p>
                                          
                                            <ul class="tags no_margin">

                                                {
                                                    artistItem.skills?.split(',').map(function (obj, i) {
                                                        return <li><a href={'/category/' + obj.replace('/', '-').replace(' ', '-')} >{obj}</a></li>
                                                    })
                                                }

                                            </ul>
                                        </div>
                                        <div class="score_in">
                                            
                                            <a href="javacript:void(0)" onClick={this.handleLike} class={this.state.isLike ? "like wish_bt" : "wish_bt"} ><i class={this.state.isLike ? "text-danger icon_heart" : "icon_heart"} ></i></a>
                                        </div>

                                    </div>

                                    <hr />
                                    <h4>About me</h4>
                                    <p dangerouslySetInnerHTML={{ __html: (artistItem.description) }}></p>
                                    <ul class="tags no_margin">
                                     

                                        {
                                            artistItem.website ? <li><a href={artistItem.website} ><i class='fa fa-globe pull-left'></i><span class='icon_txt'>Website</span></a></li> : ''
                                        }
                                        {
                                            artistItem.facebookLink ? <li><a href={artistItem.facebookLink} ><i class='fa fa-facebook pull-left'></i><span class='icon_txt'>Facebook</span></a></li> : ''
                                        }
                                        {
                                            artistItem.twitterLink ? <li><a href={artistItem.twitterLink} ><i class='fa fa-twitter pull-left'></i><span class='icon_txt'>Twitter</span></a></li> : ''
                                        }
                                        {
                                            artistItem.linkedLink ? <li><a href={artistItem.linkedLink} class='instagram' ><i class='fa fa-instagram pull-left'></i><span class='icon_txt'>Instgram</span></a></li> : ''
                                        }
                                        {
                                            artistItem.otherSocialLink ? <li><a href={artistItem.otherSocialLink} ><i class='fa fa-link pull-left'></i><span class='icon_txt'>Links</span></a></li> : ''
                                        }

                                    </ul>

                                    {/* <a href="#0" class="show_hide" data-content="toggle-text">Read More</a>*/}
                                </div>

                            </div>

                            <div class="box_general">
                                <div class="tabs_detail">
                                    <ul class="nav nav-tabs" role="tablist">
                                        <li class="nav-item">
                                            <a id="tab-A" href="#pane-A" class="nav-link active" data-bs-toggle="tab" role="tab">Other info</a>
                                        </li>

                                    </ul>
                                    <div class="tab-content" role="tablist">
                                        <div id="pane-A" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
                                            <div class="card-header" role="tab" id="heading-A">
                                                <h5>
                                                    <a class="collapsed" data-bs-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
                                                        Other info
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapse-A" class="collapse" role="tabpanel" aria-labelledby="heading-A">
                                                <div class="card-body info_content">
                                                    <div class="indent_title_in">
                                                        <img src="img/curriculum-vitae.png" />
                                                        <h3>Biography</h3>
                                                        <p>Artist Biography And Achievements</p>
                                                    </div>
                                                    <div class="wrapper_indent">
                                                        <span dangerouslySetInnerHTML={{ __html: (artistItem.biography) }}></span>
                                                    </div>

                                                    <hr />
                                                    <div class="indent_title_in">
                                                        <img src="img/party-popper.png" />
                                                        <h3>Events</h3>
                                                        <p>Most Requested Services For The Artist.</p>
                                                    </div>
                                                    <div class="wrapper_indent">
                                                        <ul class="bullets">
                                                            {
                                                                artistItem.event?.split(',').map(function (obj, i) {
                                                                    return <li>{obj}</li>
                                                                })
                                                            }
                                                        </ul>

                                                    </div>

                                                    <hr />
                                                    <div class="indent_title_in">
                                                        <img src="img/band.png" />
                                                        <h3>Performance Details</h3>
                                                        <p>Service Details Of the Artist</p>
                                                    </div>
                                                    <div class="wrapper_indent">
                                                        <div class="row">
                                                            <div class="services_list clearfix">
                                                                <ul>
                                                                    <li>Can Travel<strong>{artistItem.canTravel || 'NA'}</strong></li>
                                                                    <li>Performance Duration<strong>{artistItem.performanceDuration || 'NA'}</strong></li>
                                                                    <li>Members<strong>{artistItem.performingMembers || 'NA'}</strong></li>
                                                                    <li>Off Stage Members<strong>{artistItem.offStageMembers || 'NA'}</strong></li>

                                                                </ul>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <hr />
                                                    <div class="indent_title_in">
                                                        <img src="img/languages.png" />
                                                        <h3>Languages</h3>
                                                        <p> Artist Can Preform In These Languages.</p>
                                                    </div>
                                                    <div class="wrapper_indent add_bottom_25">
                                                        <ul class="bullets">

                                                            <ul class="bullets">
                                                                {
                                                                    artistItem.languages?.split(',').map(function (obj, i) {
                                                                        return <li>{obj} {/*<strong><small>from</small> $80</strong>*/}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>



                            <div class="box_general">
                                <div class="tabs_detail">
                                    <ul class="nav nav-tabs" role="tablist">
                                        <li class="nav-item">
                                            <a id="gtab-A" href="#gpane-A" class="nav-link active" data-bs-toggle="tab" role="tab">Gallery</a>
                                        </li>

                                    </ul>
                                    <div class="tab-content" role="tablist">
                                        <div id="gpane-A" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="gtab-A">
                                            <div class="card-header" role="tab" id="gheading-A">
                                                <h5>
                                                    <a class="collapsed" data-bs-toggle="collapse" href="#gcollapse-A" aria-expanded="true" aria-controls="gcollapse-A">
                                                        Gallery
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="gcollapse-A" class="collapse" role="tabpanel" aria-labelledby="gheading-A">
                                                <div class="card-body info_content">
                                                    {
                                                        //(photoGalObj != null) ? (
                                                            <>
                                                                <div class="indent_title_in">
                                                                    
                                                                    <img src="img/picture.png" />
                                                                
                                                                <h3>Photo Gallery</h3>
                                                                <p>Performance, Events And Achievements Gallery</p>
                                                            </div>
                                                            <div class="wrapper_indent">
                                                               
                                                                    <Fancybox
                                                                        options={{
                                                                            Carousel: {
                                                                                infinite: false,
                                                                            },
                                                                        }}
                                                                    >


                                                                    {
                                                                        photoGalObj?.map(function (obj, i) {
                                                                            return <a href={obj.Value.replace("ClientApp\\public", "")} data-fancybox="gallery" ><img class='gallery_image img-thumbnail' src={obj.Value.replace("ClientApp\\public", "")} /></a>
                                                                        })

                                                                    }

                                                                    {
                                                                           
                                                                            videoGalObj?.map(function (obj, i) {
                                                                                return obj.Value != '' ? <a href={obj.Value} data-fancybox="gallery" ><img class='gallery_image img-thumbnail' src={obj.Value.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/) != null ? ('https://img.youtube.com/vi/' + obj.Value.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop() + '/sddefault.jpg') : ""} alt="" /></a> : '';
                                                                        })
                                                                    }
                                                                    </Fancybox>
                                                                
                                                                </div>
                                                                <hr />
                                                            </>
                                                        //)
                                                        //    : ''
                                                    }

                                                    

                                                    

                                                  
                                                    <div class="wrapper_indent">
                                                       
                                                        <ul class="tags no_margin">

                                                            {
                                                                artistItem.tags?.split(',').map(function (obj, i) {
                                                                    return <li><a href={'/tags/' + obj.replace('/', '-').replace(' ', '-')} >{obj}</a></li>
                                                                })
                                                            }

                                                        </ul>
                                                    </div>

                                                  

                                                  

                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>



                            {reviews}


                            </div>

                            <div class="col-xl-4 col-lg-5" id="sidebar_fixed">
                                <div class="box_booking mobile_fixed">
                                    <div class="head">
                                        <h3>Booking</h3>
                                        <a href="#0" class="close_panel_mobile"><i class="icon_close"></i></a>
                                    </div>

                                    <div class="main">

                                    <input type="text" id="datepicker_field" />
                                        <div id="DatePicker"></div>
                                    
                                    <a href="javascript:void(0)" onClick={this.handleBookNow} class="btn_1 full-width booking">Book Now</a>
                                    </div>
                                </div>

                            <div class="btn_reserve_fixed">  <a href="javascript:void(0)" class="btn_1 full-width booking">Book Now</a></div>
                                <ul class="share-buttons">
                                <li><a class="fb-share" href={'https://www.facebook.com?sharer.php?u=' + this.state.shareUrl}><i class="social_facebook"></i> Share</a></li>
                                <li><a class="twitter-share" href={'https://twitter.com/share?text=' + this.state.artistItem?.name + ' on SpotDiale.com! &url=' + this.state.shareUrl}><i class="social_twitter"></i> Share</a></li>
                                <li><a class="whatsapp-share" href={'https://api.whatsapp.com/send?text=' + this.state.shareUrl}><i class="fa fa-whatsapp"></i> Share</a></li>
                                <li><a class="email-share" href={'mailto:?subject=Book ' + this.state.artistItem?.name + ' on SpotDiale.com!&BODY= I have found ' + this.state.artistItem.name + ' on SpotDiale.com !!. Please check this link to book this artists. ' + this.state.shareUrl}><i class="fa fa-envelope"></i> Email</a></li>
                                </ul>
                            </div>
                        </div>

                    </div >


                </>
                );
    }
}



