import React, { Component, useState } from 'react';
import '../sass/listing.scss';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import InfiniteScroll from "react-infinite-scroll-component";
import $, { param } from 'jquery';
import { SeoMeta } from './Layout/SeoMeta';
import { PopArtists } from './Home/PopArtists';
import Slider, { Range, createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';

export class Listing extends Component {

    static propTypes = {
        pageType: PropTypes.string,

    };


    static displayName = Listing.name;

    static url = "v1/api/search";
    static author = "";
    static perPage = "20";


    constructor(props) {
        super(props);

        this.state = {
            data: [],
            offset: 0,
            url: "v1/api/search",
            author: "",
            perPage: "18",
            currentPage: 1,
            searchTerm: "",
            totalPages: 0,
            filters: [],
            selectedFilters: [],
            showCategoryFilter: true,
            showEventFilter: true,
            showGenderFilter: true,
            showLanguageFilter: true,
            showLocationFilter: true,
            showBudgetFilter: true,
            showGenresFilter: true,
            pageBaseUrl: '',
            catMsg: '',
            seoPrefix: '',
            breadcrums: [],
            hasMore: true,
            sortBy: "",
            minBudget: 1000,
            maxBudget: 500000
        };
        this.state.breadcrums.push({ title: 'Home', url: '/' });
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.handleLikeClick = this.handleLikeClick.bind(this);
        this.loadArtistsFromServer = this.loadArtistsFromServer.bind(this);
        this.handleClearAllFilter = this.handleClearAllFilter.bind(this);
        this.handleClearFilter = this.handleClearFilter.bind(this);
        this.handleSort = this.handleSort.bind(this);
       
    }

    loadArtistsFromServer(isSearch) {

    
        $.ajax({
            url: this.state.url,
            data: { pageSize: this.state.perPage, pageNumber: this.state.offset + 1, searchString: this.state.searchTerm, currentFilter: this.state.selectedFilters.join(","), sortOrder: this.state.sortBy, minBudget: this.state.minBudget, maxBudget: this.state.maxBudget },
            dataType: 'json',
            type: 'GET',

            success: (data) => {


                this.setState({
                    data: data.items.length > 0 ? (isSearch ? data.items : this.state.data.concat(data.items)) : [],
                    pageCount: data.totalPages,
                    offset: data.pageIndex,
                    currentPage: data.pageIndex - 1,
                    totalPages: data.totalPages,
                    hasMore: (data.totalPages > 0 ? (data.pageIndex < data.totalPages) : false)
                });

                if (this.state.totalPages == 0) {
                    this.state.offset = 0;
                    this.state.currentPage = 1;
                    this.state.searchTerm = "";
                }

            },

            error: (xhr, status, err) => {
                console.error(this.state.url, status, err.toString()); // eslint-disable-line
            },
        });
    }


    componentDidMount() {

        const script = document.createElement("script");
        script.src = "/js/sticky_sidebar.min.js";
        script.async = false;
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "/js/specific_listing.min.js";
        script2.async = false;
        document.body.appendChild(script2);


        const query = new URLSearchParams(window.location.search);
        var q = query.get('q');
        var offset = query.get('page');
        var cat = window.location.pathname.split("/").pop();
        var filters = query.get('filters');
        var tags = window.location.hash.substr(1);
        var catTitle = ((cat != null) ? cat.replace(/-/g, " ").replace(/_/g, "/").toProperCase() : "");


        if (this.props.pageType == "Category") {

            this.state.showCategoryFilter = false;
            this.state.seoPrefix = catTitle;
            this.state.catMsg = "Book Artists for " + catTitle;
            this.loadByFilter('s__' + cat);
            this.state.breadcrums.push({ title: 'Category', url: '/category' });
            this.state.breadcrums.push({ title: catTitle, url: '/' + cat });
        }

        if (this.props.pageType == "Events") {
            this.state.showEventFilter = false;
            this.state.seoPrefix = catTitle;
            this.state.catMsg = "Book Artists for " + catTitle;
            this.loadByFilter('e__' + cat);
            this.state.breadcrums.push({ title: 'Events', url: '/events' });
            this.state.breadcrums.push({ title: catTitle, url: '/' + cat });
        }

        if (this.props.pageType == "Location") {

            this.state.showLocationFilter = false;
            this.state.seoPrefix = catTitle;
            this.state.catMsg = "Book Artists At " + catTitle;
            this.loadByFilter('lo__' + cat);
            this.state.breadcrums.push({ title: 'Location', url: '/locations' });
            this.state.breadcrums.push({ title: catTitle, url: '/' + cat });
        }

        if (this.props.pageType == "Language") {

            this.state.showLanguageFilter = false;
            this.state.seoPrefix = catTitle;
            this.state.catMsg = "Book Artists In Language " + catTitle;
            this.loadByFilter('l__' + cat);
            this.state.breadcrums.push({ title: 'Language', url: '/language' });
            this.state.breadcrums.push({ title: catTitle, url: '/' + cat });
        }

        if (this.props.pageType == "Tag") {

            this.state.showLanguageFilter = false;
            this.state.seoPrefix = tags.replace(/-/g, " ").replace(/_/g, "/");
            this.state.catMsg = 'For #' + tags.replace(/-/g, " ").replace(/_/g, "/");
            this.loadByFilter('t__#' + tags);
            this.state.breadcrums.push({ title: 'Tags', url: '/Tags' });
            this.state.breadcrums.push({ title: catTitle, url: '/' + cat });
        }

        if (filters != null) {

            // debugger
            var filtersArr = filters.split(",");
            var loc = null;
            for (const filter of filtersArr) {
                if (filter.startsWith("lo__")) {

                    loc = ((loc == null) ? filter.replace("lo__", "") : loc + "," + filter.replace("lo__", ""));
                    catTitle = loc;
                }
            }
            if (loc != null) {
                var cat = loc;
                this.state.showLanguageFilter = false;
                this.state.seoPrefix = catTitle;
                this.state.catMsg = "Book Artists In " + catTitle;
                this.loadByFilter(filters);
                this.state.breadcrums.push({ title: 'Location', url: '/locations' });
                this.state.breadcrums.push({ title: catTitle, url: '/' + cat });
            }


        }


        this.state.pageBaseUrl = window.location.href.split('?')[0]

        this.setState({ searchTerm: q, offset: (offset != null ? Number(offset) - 1 : 0) }, () => {
            this.loadArtistsFromServer(true);
        });

        this.GetFilters();

    }

    loadByFilter(filterValue) {

        if (filterValue) {
            this.state.selectedFilters.push(filterValue);
        } else {

            const index = this.state.selectedFilters.indexOf(filterValue);
            if (index > -1) {
                this.state.selectedFilters.splice(index, 1);
            }
        }
        this.loadArtistsFromServer(false);
    }


    handlePageClick = (data) => {
        console.log('onPageChange', data);
        let selected = data.selected;
        let offset = selected;

        this.setState({ offset: offset }, () => {
            this.loadArtistsFromServer(false);
            this.updateWindowsUrl();
        });
    };

    handleSearchDebounced = debounce(function () {
        console.log(this.state.searchTerm);
        this.loadArtistsFromServer(true);
        this.updateWindowsUrl();
    }, 1000);



    handleChange = (e) => {
        this.state.offset = 0;
        this.state.currentPage = 1;
        this.setState({ searchTerm: e.target.value });
        this.handleSearchDebounced();
    };

    handleChangeFilter = (e) => {
        if (e.target.checked) {

            this.state.selectedFilters.push(e.target.value);


        } else {

            const index = this.state.selectedFilters.indexOf(e.target.value);
            if (index > -1) {
                this.state.selectedFilters.splice(index, 1);
            }
        }
        this.state.offset = 0;
        this.state.data = [];
        this.loadArtistsFromServer(true);
        this.updateWindowsUrl();

        //return false;
    };

    handleLikeClick = async (e, item, idx) => {

        var isLike = this.state.data[idx].isLiked;

        const response = await fetch('v1/api/user/toggleWishlist', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "ArtistId": item.id,
                "isLike": !isLike
            })
        });
        const data = await response.json();
        if (data.status) {

            item.isLiked = data.data;
            this.state.data[idx] = item;
            var data2 = this.state.data;
            this.setState({
                data: data2
            })


        } else {
            //

        }
    };

    handleFilterSubmit = (e) => {
        this.loadArtistsFromServer(false);

        $('.filter_col').removeClass('show');
        $('main').removeClass('freeze');
        $('.layer').removeClass('layer-is-visible');
       
        e.preventDefault();
        return false;
    };

    handleClearAllFilter = (e) => {

        this.state.selectedFilters = [];
        this.state.offset = 0;
        this.state.currentPage = 1;
        this.state.searchTerm = "";

        this.updateWindowsUrl();
        this.loadArtistsFromServer(true);
        e.preventDefault();
        return false;
    };

    handleClearFilter = (e) => {
        
        var filterValue = e.target.getAttribute('data-item');
        const index = this.state.selectedFilters.indexOf(filterValue);
        if (index > -1) {
            this.state.selectedFilters.splice(index, 1);
            this.state.offset = 0;
            this.state.currentPage = 1;
            this.state.searchTerm = "";

        }
        this.updateWindowsUrl();
        this.loadArtistsFromServer(true);
        e.preventDefault();
        return false;

    }


    handleSort = (e) => {

        var sortBy = e.target.value;
        this.state.sortBy = sortBy;
        
        this.state.offset = 0;
        this.state.currentPage = 1;        
        this.updateWindowsUrl();
        this.loadArtistsFromServer(false);
        e.preventDefault();
        return false;

    }

    updateWindowsUrl = () => {


        var q = this.state.searchTerm;
        var offset = this.state.totalPages > 0 ? this.state.offset : 0;
        var filters = this.state.selectedFilters;

        var params = [];
        if (q != "" || q != null) {
            params.push("q=" + q);
        }
        if (offset >= 0) {
            params.push("page=" + (Number(offset) + 1));
        }
        if (filters.length > 0) {
            params.push("filters=" + filters.join(","));
        }

        console.log("Params " + params);
        if (params.length > 0) {
            window.history.replaceState(null, "Search & Pagination", this.state.pageBaseUrl + "?" + params.join('&'));
        }


    };


    async GetFilters() {

        $.ajax({
            url: 'v1/api/Filters',
            data: {},
            dataType: 'json',
            type: 'POST',

            success: (data) => {
                this.setState({ filters: data.data, loading: false });
            },

            error: (xhr, status, err) => {
                console.error(this.state.url, status, err.toString()); // eslint-disable-line
            },
        });
    }


    renderCheckboxes(checkboxes, prefix) {
        return checkboxes?.map((item, idx) => { // here

            var isSelected = false;
            const index = this.state.selectedFilters.indexOf(prefix + '__' + item.name);
            if (index > -1) {
                isSelected = true;
            }

            return (
                <>
                    <li><label class="container_check">{item.name}<input type="checkbox" checked={isSelected} value={prefix + '__' + item.name} onChange={(event) => this.handleChangeFilter(event)} /><span class="checkmark"></span></label></li>
                </>
            );
        });
    }

    renderFilter(title, data, prefix, showFilter, isOpen) {


        return showFilter ? (<div class="filter_type" >
            <h4><a href={'#filter_' + prefix} data-bs-toggle="collapse" class={isOpen ? 'opened' : 'collapsed closed'} >{title}</a></h4>
            <div class={isOpen ? "collapse show" : "collapse" } id={'filter_' + prefix} >
                <ul>
                    {this.renderCheckboxes(data, prefix)}
                </ul>
            </div>

        </div>) : "";

    }

    renderFilterBudget(title, data, prefix, showFilter, isOpen) {


        return showFilter ? (<div class="filter_type" >
            <h4><a href={'#filter_' + prefix} data-bs-toggle="collapse" class={isOpen ? 'opened' : 'collapsed closed'} >{title}(in Rupees)</a></h4>
            <div class={isOpen ? "collapse show" : "collapse"} id={'filter_' + prefix} >
                <br/>
                <Slider range min={1000} max={500000} onAfterChange={this.didOnAfterChangeTrigger} onChange={this.onChangeTrigger} step={1000} defaultValue={[1000, 500000]} allowCross={false} count={1} marks={{
                    1000: '1000',
                    500000: '500000',
                }} />
                <br />
                <ul>
                   
                    <li>Min Budget: Rs. {this.state.minBudget}</li>
                    <li>Max Budget: Rs. {this.state.maxBudget}</li>
                </ul>
            </div>

        </div>) : "";

    }

    didOnAfterChangeTrigger = (value) => {
        console.log(`OnAfterChange event triggered at: ${value}`);
        this.state.offset = 0;
        this.state.data = [];
        this.setState({ minBudget : value[0], maxBudget : value[1] }, () => {
            this.loadArtistsFromServer(true);
        });     
    };

    onChangeTrigger = (value) => {
        console.log(`OnAfterChange event triggered at: ${value}`);
        this.setState({ minBudget: value[0], maxBudget: value[1] });
    };

   

    renderArtistItems(data) {

        return data?.map((item, idx) => { // here
            return (
                <>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6" data-key={idx}>
                        <div class="strip">
                            <figure>
                                <a href="javascript:void(0);" onClick={(event) => this.handleLikeClick(event, item, idx)} data-id={item.id} class="wish_bt"><i class={item.isLiked ? "text-danger icon_heart" : "icon_heart"} ></i></a>
                                <img src={item.imageUrl} class="img-fluid strip-img" alt={item.title} />
                                <a href={item.url} class="strip_info">
                                    <div class="item_title">
                                        <h3>{item.title}</h3>
                                        <small class="text_wrap truncate pop_txt"><span dangerouslySetInnerHTML={{ __html: (item.bio) }}></span></small>
                                    </div>
                                </a>
                            </figure>
                            {/*<ul>
                            <li><a href="#0" class="tooltip-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Available Appointment"><i class="icon-users"></i></a></li>
                            <li><a href="#0" class="tooltip-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Available Chat"><i class="icon-chat"></i></a></li>
                            <li>
                                <div class="score"><span>Superb<em>350 Reviews</em></span><strong>8.9</strong></div>
                            </li>
                        </ul>*/}
                        </div>
                    </div>
                </>
            );
        });
    }

    renderAritstList(data) {

        if (data != null && data.length > 0) {
            return (
                <div class="row grid_sidebar " id="infinite_scroll">
                    {this.renderArtistItems(data)}
                </div>
            );
        } else {

            return (<></>);
        }
    }

    renderBreadcrumItems(data) {

        return data?.map((item, idx) => { // here
            return (
                <>
                    <li><a href={item.url}>{item.title}</a></li>
                </>
            );
        });
    }

    renderBreadcrums(data) {

        return (
            <ul class='float-end'>{this.renderBreadcrumItems(data)}</ul>
        );
    }

    selectedFilterItem(data) {

        return data?.map((item, idx) => { // here
            return (
                <>
                    <li><a href='javascript:void(0)' onClick={(event) => this.handleClearFilter(event)} data-item={item}>{item.replace("lo__", "").replace("e__", "").replace("l__", "").replace("s__", "").replace("g__", "").replace("x__", "")} <i data-item={item} class="icon_close"></i></a></li> 
                </>
            );
        });
    }

    selectedFilters(data) {
        if (data != null && data.length > 0) {
            return (

                <div class="">
                    <ul>
                        {this.selectedFilterItem(data)}
                    </ul>
                    <div class='clear_all_btn'><a href="javascript:void(0);" onClick={this.handleClearAllFilter} class='small'>Clear All</a></div>
                </div>

            );
        } else {

            return (<div></div>);
        }
    }

  
    render() {



        return (
            <>
                <SeoMeta pageType={this.props.pageType} prefix={this.state.seoPrefix} breadcrums={this.state.breadcrums} />
                <div class="filters_full version_2">
                    <div class="container clearfix">
                        <div class="sort_select">
                            <select name="sort" id="sort" onChange={this.handleSort}>
                                <option value="popularity" selected="selected">Sort by Popularity</option>
                                <option value="rating">Sort by Average rating</option>
                                <option value="date">Sort by newness</option>
                            </select>
                        </div>
                        <a href="javascript:void(0);" class="open_filters btn_filters"><i class="icon_adjust-vert"></i><span>Filters</span></a>

                        <div class="search_bar_list">
                            <input type="text" class="form-control" placeholder="Search..." value={this.state.searchTerm} onChange={this.handleChange} />
                        </div>
                        <a class="btn_search_mobile btn_filters" data-bs-toggle="collapse" href="#collapseSearch"><i class="icon_search"></i></a>
                    </div>



                    <div class="collapse" id="collapseSearch">
                        <div class="search_bar_list">
                            <input type="text" class="form-control" placeholder="Search again..." />
                        </div>
                    </div>

                </div>

                <div class="container margin_30_40">
                    <div class="page_header breadcrumbs">
                        <h1>{this.state.catMsg}</h1>

                        {this.renderBreadcrums(this.state.breadcrums)}
                    </div>
                    <div class="row">
                        <aside class="col-lg-3" id="sidebar_fixed">
                           
                            <div class="filter_col">
                                <div class="inner_bt"><a href="javascript:void(0);" class="open_filters"><i class="icon_close"></i></a></div>
                                {this.selectedFilters(this.state.selectedFilters)}                               

                                {this.renderFilterBudget('Budget', this.state.filters[0]?.BUDGET, 'x', this.state.showBudgetFilter, true)}
                                {this.renderFilter('Categories', this.state.filters[0]?.SKILLS, 's', this.state.showCategoryFilter, true)}
                                {this.renderFilter('Events', this.state.filters[0]?.EVENTS, 'e', this.state.showEventFilter, false)}
                                {this.renderFilter('Genres', this.state.filters[0]?.GENRES, 'g', this.state.showGenresFilter, false)}
                                {this.renderFilter('Locations', this.state.filters[0]?.LOCATIONS, 'lo', this.state.showLocationFilter, false)}
                                {this.renderFilter('Language', this.state.filters[0]?.LANGUAGE, 'l', this.state.showLanguageFilter, false)}
                                {this.renderFilter('Gender', this.state.filters[0]?.GENDERS, 'x', this.state.showGenderFilter, false)}
                                


                                <div class="buttons">
                                    <a href="javascript:void(0);" onClick={this.handleFilterSubmit} class="btn_1 full-width">Filter</a>
                                </div>
                            </div>
                        </aside>

                        <div class="col-lg-9">


                            {this.renderAritstList(this.state.data)}


                            <InfiniteScroll


                                dataLength={this.state.currentPage == 0 ? this.state.perPage : this.state.currentPage * this.state.perPage} //This is important field to render the next data
                                next={this.loadArtistsFromServer}
                                hasMore={this.state.hasMore}
                                pullDownToRefreshThreshold={50}
                                loader={<p style={{ textAlign: 'center' }}>
                                    <b>Loading more...</b>
                                </p>}
                                endMessage={


                                    <p style={{ textAlign: 'center' }}>
                                        {
                                            this.state.totalPages == 0 ? <><b>No data available at the moment, check out these options</b><PopArtists propTypes="art" /></>
                                                : ''
                                        }
                                    </p>
                                }

                            >

                            </InfiniteScroll>

                        </div>

                    </div>
                </div>


            </>
        );
    }
}
