import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import './NavMenu.css';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";

export class SeoMeta extends Component {

    static propTypes = {
        pageType: PropTypes.string,
        prefix: PropTypes.string,
        breadcrums: PropTypes.array,
        artist: PropTypes.object

    };


    constructor(props) {
        super(props);

        this.state = {
            seo: [],

        };
    }


    componentDidMount() {

        this.populateSeo();

    }

    async populateSeo() {
        const response = await fetch('/v1/api/cmsSeo?pageType=' + this.props.pageType, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }

        });
        const data = await response.json();

        this.setState({ seo: data.data });
    }

    static MetaData(seoData, prefix, breadcrums, artistData) {

        const currentURL = window.location.href
        let breadcrumJson = SeoMeta.GenerateBreadcrums(breadcrums);
        let schemJson = SeoMeta.GenerateSearchSchema();
        let logoJson = SeoMeta.GenerateLogo();
        let artistJson = SeoMeta.GenerateProducInfo(artistData);

        if (seoData != undefined && seoData.seoTitle != undefined) {

            return (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{seoData.seoTitle.replace(/{Category_Name}/g, prefix)}</title>
                    <link rel="canonical" href={currentURL} />
                    <meta name="title" content={seoData.seoTitle.replace(/{Category_Name}/g, prefix)} />
                    <meta name="description" content={seoData.metaDescription.replace(/{Category_Name}/g, prefix)} />
                    <script type="application/ld+json">
                        {breadcrumJson}
                    </script>
                    <script type="application/ld+json">
                        {logoJson}
                    </script>
                    <script type="application/ld+json">
                        {schemJson}
                    </script>
                    <script type="application/ld+json">
                        {artistJson}
                    </script>
                
                </Helmet>
            );
        }
    }

    static GenerateBreadcrums(breadcrums) {

        var breacrumObj = { "@context": "http://schema.org", "@type": "BreadcrumbList", "itemListElement": [] };

        if (breadcrums != null && breadcrums.length > 0) {

            breadcrums?.map(function (item, idx) {
                breacrumObj.itemListElement.push({

                    "@type": "ListItem",
                    "position": idx + 1,
                    "item": {
                        "@id": 'https://spotdiale.com' + item.url,
                        "name": item.name
                    }
                });

            });

            console.log(JSON.stringify(breacrumObj));
            return (
                JSON.stringify(breacrumObj, null, 2)
            );
        }
    }

    static GenerateSearchSchema() {

        var searchObj = {
            "@context": "http://schema.org", "@type": "WebSite", "url": "https://www.spotdiale.com/", "potentialAction": {

                "@type": "SearchAction",
                "target": {
                    "@type": "EntryPoint",
                    "urlTemplate": "https://www.spotdiale.com/search?q={search_term_string}"
                },
                "query-input": "required name=search_term_string"

            }
        };

        console.log(JSON.stringify(searchObj));
        return (
            JSON.stringify(searchObj, null, 2)
        );


    }

    static GenerateLogo() {

        var searchObj = {
            "@context": "http://schema.org", "@type": "Organization", "url": "https://www.spotdiale.com/", "logo": "https://www.spotdiale.com/img/logo/logo_spotdaile.svg"
        };

        console.log(JSON.stringify(searchObj));
        return (
            JSON.stringify(searchObj, null, 2)
        );
    }

    static GenerateProducInfo(artistItem) {
       // debugger
        if (artistItem != null) {
            var searchObj = {
                "@context": "http://schema.org", "@type": "Product", "name": artistItem.name, "description": artistItem.biograpy, "sku": artistItem.id, "image": []
            };

            searchObj.image = [];
            searchObj.image.push(artistItem.profileImage);

            console.log(JSON.stringify(searchObj));
            return (
                JSON.stringify(searchObj, null, 2)
            );

        } else {
            return '';
        }

    }

    render() {

        let seoHeaders = SeoMeta.MetaData(this.state.seo, this.props.prefix, this.props.breadcrums, this.props.artist);

        return (
            <>
                {seoHeaders}


            </>);
    }
}
