import React, { Component } from 'react';
import { SeoMeta } from './Layout/SeoMeta';

export class Profile extends Component {
    static displayName = Profile.name;

  constructor(props) {
    super(props);
    
  }

 
  render() {
    return (
        <div class="bg_gray">
            <SeoMeta pageType={this.props.pageType} />
            <div class="container margin_60_40">
                <iframe src="/Identity/Account/Manage" frameBorder="0" width="100%" height="800px"></iframe>
            </div>
        </div>
    );
  }
}
