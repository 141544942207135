import React, { Component } from 'react';
import $, { param } from 'jquery';
import ReactGA from 'react-ga';

export class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {

            status: false,
            email: '',
            IsFormSubmitted: false,

        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    async handleSubmit(event) {

        var formData = {

            "Email": this.state.email,

        };

        $.ajax({
            url: 'v1/api/user/subscribe',
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            type: 'POST',

            success: (data) => {

                if (data.status) {

                    ReactGA.event({
                        category: 'Subscribe',
                        action: 'new Subscribtion added'
                    });

                    this.setState({
                        IsFormSubmitted: true,
                        status: true
                    }, function () {

                    });


                } else {

                }
            },

            error: (xhr, status, err) => {
                console.error(status, err.toString()); // eslint-disable-line
            },
        });


        event.preventDefault();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    render() {

        const showComfirm = !this.state.IsFormSubmitted;

        return (
            <>
                <footer>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-3">
                                <h3 data-bs-target="#collapse_2">About Us</h3>
                                <div class="collapse dont-collapse-sm links" id="collapse_2">
                                    <p>
                                        Welcome To SpotdialE - an innovative platform by 9X Media, India's largest music broadcast network. SpotdialE provides a unique opportunity for singers, musicians and anyone related with music (Artist) to showcase their talent/offerings, to gain recognition, and potential benefits. <a href="/about-us">Read More</a>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3">
                                <h3 data-bs-target="#collapse_1">Quick Links</h3>
                                <div class="collapse dont-collapse-sm links" id="collapse_1">
                                    <ul>
                                        <li><a href="/join-for-artist">Register</a></li>                                      
                                        <li><a href="/login?ReturnUrl=%2F">Login</a></li>
                                        <li><a href="/contact-us">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3">
                                <h3 data-bs-target="#collapse_2">Contact Us</h3>
                                <div class="collapse dont-collapse-sm links" id="collapse_2">
                                    <ul>
                                        <li><a href="tel:+912266019999"><i class='fa fa-phone'></i> +91 22 6601 9999</a></li>
                                        <li><a href="mailto:info@spotdiale.com"><i class='fa fa-envelope'></i> info@spotdiale.com</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3">
                                <h3 data-bs-target="#collapse_4">Keep in touch</h3>
                                <div class="collapse dont-collapse-sm" id="collapse_4">
                                    <div id="newsletter">
                                        <div id="message-newsletter"></div>
                                        {
                                            showComfirm ? (<>
                                                <form onSubmit={this.handleSubmit} >
                                                    <div class="form-group">
                                                        <input type="email" id="email" class="form-control" placeholder="Your email" value={this.state.email} onChange={this.handleInputChange} name="email" />
                                                        <button type="submit" id="submit-newsletter"><i class="arrow_carrot-right"></i></button>
                                                    </div>
                                                </form>
                                            </>) : <p>{this.state.status ? 'Thanks for subscribing!!' : 'Error !'}</p>

                                        }

                                    </div>
                                    <div class="follow_us">
                                        <ul>
                                            <li><a href="https://twitter.com/spotDialE"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="img/twitter_icon.svg" alt="" class="lazy" /></a></li>
                                            <li><a href="https://www.facebook.com/spotdiale"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="img/facebook_icon.svg" alt="" class="lazy" /></a></li>
                                            <li><a href="https://www.instagram.com/spotdiale/"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="img/instagram_icon.svg" alt="" class="lazy" /></a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />
                        <div class="row add_bottom_25">
                            <div class="col-lg-6">
                                {/*<ul class="footer-selector clearfix">								
								<li><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="img/cards_all.svg" alt="" width="230" height="35" class="lazy" /></li>
							</ul>*/}
                            </div>
                            <div class="col-lg-6">
                                <ul class="additional_links">
                                    <li><a href="/terms-and-conditions">Terms and conditions</a></li>
                                    <li><a href="/privacy">Privacy</a></li>
                                    <li><span>@ SpotDialE</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>

            </>
        );
    }
}
