export function getSelectedDataArray(arr) {

    var v = [];
    if (arr != null && arr.length > 0) {
        for (var i = 0; i < arr.length; i++) {
            v.push(arr[i].text);
        }
        return v.join(",");
    }
    return "";
}

export function getSelectedData(arr) {

    return (arr != null && arr.length > 0) ? arr[0].text : "";

}