import React, { Component } from 'react';
import { Banner } from './Home/Banner';
import { SeoMeta } from './Layout/SeoMeta';
import { PopCategory } from './Home/PopCategory';
import { PopArtists } from './Home/PopArtists';
import '../sass/home.scss';



export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            catData: {},
            breadcrums: []
        };
        this.state.breadcrums.push({ title: 'Home', url: '/' });
      
    }


    componentDidMount() {

        

        this.populateWeatherData();
    }

    async populateWeatherData() {
        const response = await fetch('v1/api/cmsPage?pageType=' + this.props.pageType, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        if (data.status) {
            this.setState({ data: data.data, loading: false });
        } else {
            //
            this.setState({ data: null, loading: false });
        }
    }

    

    render() {

       
        return (
            <>
                <SeoMeta pageType={this.props.pageType} breadcrums={this.state.breadcrums} />
                <Banner pageData={this.state.data} />
                <PopCategory propTypes="Pop" />
                <PopArtists propTypes="art" />

                

                <div dangerouslySetInnerHTML={{ __html: this.state.data.content }}></div> 

                <div class="layer"></div>

                <div class="popup_wrapper">
                    <div class="popup_content">
                        <span class="popup_close">Close</span>
                        <a href="/artists"><img class="img-fluid" src="img/Book_Now_Banner.jpg" alt="" width="500" height="500" /></a><a class="btn btn-outline-danger text-center m-3" href="/artists" >Book Now</a>
                    </div>
                </div>

            </>
        );
    }
}
