import { ArtistDetails } from "./components/ArtistDetails";
import { BlogDetails } from "./components/BlogDetails";
import { Blogs } from "./components/Blogs";
import { Home } from "./components/Home";
import { Listing } from "./components/Listing";
import { NoPage } from "./components/NoPage";
import { CmsPage } from "./components/CmsPage";
import { Booking } from "./components/Booking";
import { Review } from "./components/Review";
import { Wishlist } from "./components/Wishlist";
import { BookingList } from "./components/BookingList";
import { Profile } from "./components/Profile";
import { JoinNow } from "./components/JoinNow";
import { CategoryListing } from "./components/CategoryListing";
import { CustomListing } from "./components/CustomListing";
import { PageListing } from "./components/PageListing";
import { ContactCms } from "./components/ContactCms";


const AppRoutes = [
    {
        index: true,
        element: <Home pageType="Home" />
    },
    {
        path: '/blogs',
        element: <Blogs pageType="Blogs" />
    },
    {
        path: '/contact-us',
        element: <ContactCms pageType="Contacts" />
    },
    {
        path: '/privacy',
        element: <CmsPage pageType="Privacy" />
    },
    {
        path: '/help',
        element: <CmsPage pageType="Help" />
    },
    {
        path: '/terms-and-conditions',
        element: <CmsPage pageType="TermConditions" />
    },
    {
        path: '/blogs/*',
        element: <BlogDetails pageType="BlogDetails" />
    },
    {
        path: '/book-an-artist',
        element: <Listing pageType="BookAnArtist" />
    },
    {
        path: '/book-an-artist/*',
        element: <Listing pageType="Artists" />
    },
    {
        path: '/search/*',
        element: <Listing pageType="Search" />
    },
    {
        path: '/category',
        element: <CategoryListing pageType="CategoryList" />
    },
    {
        path: '/events',
        element: <CategoryListing pageType="EventsList" />
    },
    {
        path: '/locations',
        element: <CategoryListing pageType="LocationList" />
    },
    {
        path: '/category/*',
        element: <Listing pageType="Category" />
    },
    {
        path: '/events/*',
        element: <Listing pageType="Events" />
    },
    {
        path: '/locations/*',
        element: <Listing pageType="Location" />
    },
    {
        path: '/tags/*',
        element: <Listing pageType="Tag" />
    },
    {
        path: '/artist',
        element: <Listing pageType="Search" />
    },
    {
        path: '/artists',
        element: <Listing pageType="Search" />
    },
    {
        path: '/artists/*',
        element: <ArtistDetails pageType="ArtistDetails" />
    },  
    {
        path: '/artist/*',
        element: <ArtistDetails pageType="ArtistDetails" />
    },  
    {
        path: '/book/*',
        element: <Booking pageType="Booking" />
    },
    {
        path: '/booking/*',
        element: <Booking pageType="Booking" />
    },
    {
        path: '/review/*',
        element: <Review pageType="Review" />
    },
    {
        path: '/wishlist',
        element: <Wishlist pageType="wishlist" />
    },
    {
        path: '/wishlist/*',
        element: <Wishlist pageType="Wishlist" />
    },
    {
        path: '/bookingList/*',
        element: <BookingList pageType="BookingList" />
    },
    {
        path: '/profile/*',
        element: <Profile pageType="Profile" />
    },
    {
        path: '/join/*',
        element: <JoinNow pageType="Join" />
    },
    {
        path: '/join-for-artist/*',
        element: <JoinNow pageType="Join" />
    },   
    {
        path: '/pages',
        element: <PageListing pageType="PageListing" />
    },
    {
        path: '/about-us/*',
        element: <CmsPage pageType="AboutUs" />
    },
    {
        path: '/pages/*',
        element: <CustomListing pageType="CustomPageList" />
    },
    {
        path: '/*',
        element: <NoPage pageType="404" />
    },


];

export default AppRoutes;
