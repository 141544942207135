import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import Autosuggest from 'react-autosuggest';
import './autosuggest.css';
import PropTypes from 'prop-types';
import $, { param } from 'jquery';


class ServerAutoSuggest extends React.Component {
    constructor() {
        super();

        //Define state for value and suggestion collection
        this.state = {
            value: '',
            suggestions: []
        };
    }
    componentDidMount() {

        const script = document.createElement("script");
        script.src = "/select2/js/select2.full.min.js";
        script.async = false;
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "/js/home_func.js";
        script2.async = false;
        document.body.appendChild(script2);

    }

    // Filter logic
    getSuggestions = async (value) => {
        const inputValue = value.trim().toLowerCase();
        const loc = $('#city_list').val();
        console.log('Selectd Location ' + loc);
        const response = await fetch('v1/api/searchAuto?searchString=' + inputValue+ '&currentFilter='+loc, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          /*  body: JSON.stringify({
                "searchString": inputValue
            })*/
        });
       
        let data = await response.json()
        return data;



    };

    // Trigger suggestions
    getSuggestionValue = suggestion => suggestion.data;

    // Render Each Option
    renderSuggestion = suggestion => (
        <a href={suggestion.url}>
        <span className="sugg-option">            
                {
                    suggestion.imageUrl != null ? (<span className="icon-wrap"><img src={suggestion.imageUrl} alt={suggestion.title} /></span>) : ''
                }
            <span className="name">
                    {suggestion.title}
                </span>
          
            </span>
        </a>
    );

    // OnChange event handler
    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };


    handleSearchDebounced = debounce(function (value) {
        this.getSuggestions(value)
            .then(data => {
                if (data.Error) {
                    this.setState({
                        suggestions: []
                    });
                } else {
                    this.setState({
                        suggestions: data.data
                    });
                }
            })
    }, 650);


    // Suggestion rerender when user types
    onSuggestionsFetchRequested = ({ value }) => {
        this.handleSearchDebounced(value);
    };

    // Triggered on clear
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: {}
        });
    };

    renderSectionTitle(section) {
        return (
            <strong>{section.title}</strong>
        );
    }

    getSectionSuggestions(section) {
        return section.suggestions;
    }

    render() {
        const { value, suggestions } = this.state;

        // Option props
        const inputProps = {
            placeholder: 'Type what you need ',
            value,
            onChange: this.onChange,
            name: 'q'
        };

        // Adding AutoSuggest component
        return (
            <Autosuggest
                multiSection={true}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                renderSectionTitle={this.renderSectionTitle}
                getSectionSuggestions={this.getSectionSuggestions}
                inputProps={inputProps}
            />
        );
    }
}

export class Banner extends Component {

    static propTypes = {
        pageData: PropTypes.object,

    };


  render() {
	  return (
		<>
              <div class="hero_single version_2 full_height">
				  <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
                      <div class="container">
                          <center>
						  <div class="row">
                                  <div class="col-sm-12">
                                      <h1>Find an Artist, Performer or Entertainer</h1>
                                      <p>Hire an Artist for All Your Special Events</p>
								  <form method="get" action="/search">

                                      <div class="form-group">
                                      <div class="row g-0 custom-search-input">
                                          
                                          <div class="col-md-3">

                                                  <select id="city_list" name="filters" class="form-control"  ></select>
                                             
                                          </div>
										  <div class="col-md-6">
                                              
                                                  
                                                  <ServerAutoSuggest />
											
                                            
                                          </div>
										  <div class="col-md-3">
											  <input type="submit" value="Search" />
                                              </div>
                                          </div>
									  </div>

									  {/*<div class="search_trends">
										  <h5>Trending:</h5>
										 <ul>
											  <li><a href="#0">doctor</a></li>
											  <li><a href="#0">lawyer</a></li>											 
										  </ul>
									  </div>*/}
								  </form>
							  </div>
                              </div>
                          </center>
                          <a href="#first_section" class="btn_explore"><span class="pulse_bt"><i class="arrow_down"></i></span></a>
                      </div>                     
                     {/* <img src="img/home-banner-new.png" class="hero-img lazy" alt="" />*/}
				  </div>
			  </div>

		</>
    );
  }
}
