import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './sass/bootstrap.min.css';
import "./sass/style.scss"
import './sass/custom.scss';

export default class App extends Component {
  static displayName = App.name;


    componentWillMount() {


        const script = document.createElement("script");
        script.src = "/js/common_scripts.min.js";
        script.async = false;
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "/js/common_func.js";
        script2.async = false;
        document.body.appendChild(script2);

        const script3 = document.createElement("script");
        script3.src = "/assets/validate.js";
        script3.async = false;
        document.body.appendChild(script3);
    }

  render() {
    return (
      <Layout>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
        </Routes>
      </Layout>
    );
  }
}
