import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../sass/review.scss';
import $, { param } from 'jquery';
import { SeoMeta } from './Layout/SeoMeta';
import { Store } from 'react-notifications-component';
import ReactGA from 'react-ga';
export class Review extends Component {
    static displayName = Review.name;



    static propTypes = {
        pageType: PropTypes.string,

    };


    constructor(props) {
        super(props);
        this.state = {
            artistItem: [],
            artistId: '',
            loading: true,
            responseTime: 5,
            service: 5,
            communication: 5,
            price:5,
            title: '',
            review: '',
            ack: '',
            IsFormSubmitted: false,
            reviewObj: {}
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }


    componentDidMount() {

        const script = document.createElement("script");
        script.src = "/js/specific_review.js";
        script.async = false;
        document.body.appendChild(script);



        const query = new URLSearchParams(window.location.search);
        var id = query.get('artistId');


        this.populateArtistData(id);

    }

    async populateArtistData(id, date) {
        const response = await fetch('v1/api/booking/ArtistById', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "Id": id
            })
        });
        const data = await response.json();
        if (data.status) {
            this.setState({ artistItem: data.data, loading: false, artistId: data.data.id });
        } else {
            //
            this.setState({ artistItem: null, loading: false });

        }
    }

    handleSubmit(event) {

        var formData = {
            "Id": 0,
            "ArtistId": this.state.artistId,
            "ReviewTitle": this.state.title,
            "ReviewDescription": this.state.review,
            "ResponseTimeRating": Number(this.state.responseTime),
            "ServiceRating": Number(this.state.service),
            "CommunicationRating": Number(this.state.communication),
            "PriceRating": Number(this.state.price)
        };

       

        $.ajax({
            url: 'v1/api/review/create',
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            type: 'POST',
            success: (data) => {

                  if (data.status) {
  
                      this.setState({
                          IsFormSubmitted: true,
                          responseTime: '',
                          service: '',
                          communication: '',
                          price: '',
                          title: '',
                          review: '',
                          ack: '',
                          reviewObj: data.data
  
                      }, function () {
  
                      });

                      ReactGA.event({
                          category: 'Review',
                          action: 'New Review Added'
                      });

  
  
                  } else {

                      if (data.statusMessage == "LoginRequired") {

                          Store.addNotification({
                              title: "Please login!",
                              message: "Redirecting to login page..",
                              type: "warning",
                              insert: "top",
                              container: "bottom-right",
                              animationIn: ["animate__animated", "animate__fadeIn"],
                              animationOut: ["animate__animated", "animate__fadeOut"],
                              dismiss: {
                                  duration: 2000,
                                  onScreen: true
                              },
                              onRemoval: (id, removedBy) => {
                                  window.location.href = '/login';
                              }
                          });
                      }
                  }

            },
            error: (xhr, status, err) => {
                console.error(status, err.toString()); // eslint-disable-line
            },
        });


        event.preventDefault();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    render() {

        const artistItem = this.state.artistItem;
        const booking = this.state.booking;
        const showComfirm = !this.state.IsFormSubmitted;

        if (showComfirm) {

            return (
                <>
                    <SeoMeta pageType={this.props.pageType} />
                    <form onSubmit={this.handleSubmit}>
                        <input type="hidden" value={this.state.artistId} name="artistId" />
                        <div class="container margin_60_40">
                            <div class="row justify-content-center">
                                <div class="col-lg-8">
                                    <div class="box_general write_review">
                                        <h1 class="add_bottom_15">Write a review for "{artistItem.name}"</h1>

                                        <label class="d-block add_bottom_15">Overall rating</label>
                                        <div class="row">
                                            <div class="col-md-3 add_bottom_25">
                                                <div class="add_bottom_15">Response time <strong class="food_quality_val"></strong></div>
                                                <input type="range" min="0" max="10" step="1" value="0" data-orientation="horizontal" id="food_quality" name="responseTime" value={this.state.responseTime} onChange={this.handleInputChange} />
                                            </div>
                                            <div class="col-md-3 add_bottom_25">
                                                <div class="add_bottom_15">Service <strong class="service_val"></strong></div>
                                                <input type="range" min="0" max="10" step="1" value="0" data-orientation="horizontal" id="service" name="service" value={this.state.service} onChange={this.handleInputChange} />
                                            </div>
                                            <div class="col-md-3 add_bottom_25">
                                                <div class="add_bottom_15">Communication <strong class="location_val"></strong></div>
                                                <input type="range" min="0" max="10" step="1" value="0" data-orientation="horizontal" id="location" name="communication" value={this.state.communication} onChange={this.handleInputChange} />
                                            </div>
                                            <div class="col-md-3 add_bottom_25">
                                                <div class="add_bottom_15">Price <strong class="price_val"></strong></div>
                                                <input type="range" min="0" max="10" step="1" value="0" data-orientation="horizontal" id="price" name="price" value={this.state.price} onChange={this.handleInputChange} />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label>Title of your review</label>
                                            <input class="form-control" type="text" placeholder="If you could say it in one sentence, what would you say?" name="title" value={this.state.title} onChange={this.handleInputChange} required />
                                        </div>
                                        <div class="form-group">
                                            <label>Your review</label>
                                            <textarea class="form-control" placeholder="Write your review to help others learn about this online business" name="review" value={this.state.review} onChange={this.handleInputChange} required />
                                        </div>

                                        <div class="form-group">
                                            <div class="checkboxes float-start add_bottom_15 add_top_15">
                                                <label class="container_check">I agree to <a href="/terms-and-conditions" >Terms & Conditions</a>
                                                    <input type="checkbox" value={this.state.ack} onChange={this.handleInputChange} name="ack" required />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <input type="submit" class="btn_1 full-width mb_5" value="Submit Review" />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </form>
                </>
            );
        } else {

            return (<>

                <div class="container margin_60_40">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="box_general write_review">
                              
                                  {/*  <h1 class="add_bottom_15">Write a review for "{artistItem.name}"</h1>*/}
                               

                                <div class="main">
                                    <div id="confirm">
                                        <div class="icon icon--order-success svg add_bottom_15">
                                            <img src="img/confirm.svg" />
                                        </div>
                                        <h3>Your Review is Submitted !</h3>
                                        <p>We will forward your review to the artist.</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </>);
        }
    }
}
