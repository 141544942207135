import React, { Component } from 'react';
import { SeoMeta } from './Layout/SeoMeta';
import '../sass/error.scss';


export class NoPage extends Component {
    static displayName = NoPage.name;



    render() {
        return (
            <>
               
                <SeoMeta pageType={this.props.pageType} />
                <main>
                    <div id="error_page">
                        <div class="container">
                            <div class="row justify-content-center text-center">
                                <div class="col-xl-7 col-lg-9">
                                    <figure><img src="img/404.svg" alt="" class="img-fluid" /></figure>
                                    <p>We're sorry, but the page you were looking for doesn't exist.</p>                                       
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
                
            </>
        );
    }
}
