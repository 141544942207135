import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";

export class PopCategory extends Component {

    static propTypes = {
        pageType: PropTypes.string,

    };


    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,

        };
    }


    componentDidMount() {

        this.populateCat();


    }

    async populateCat() {
        const response = await fetch('v1/api/home/category/search', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        
        this.setState({ data: data, loading: false });

        const script2 = document.createElement("script");
        script2.src = "/js/pop_cat.js";
        script2.async = false;
        document.body.appendChild(script2);
        
    }

    static renderCatItem(dataItem) {
        return dataItem?.map((obj, idx) => { // here
            return (
                <>
                    <div class="item_version_2">
                        <a href={obj.url} >
                            <img src={obj.imageUrl} alt={obj.title} class="" />
                            <div class="info">
                                <h3>{obj.title}</h3>
                            </div>
                        </a>
                    </div>
                </>
            );
        });
    }

    static renderCat(data) {
              
        
        if (data != null && data.length > 0) {

            return (
                <div class="bg_gray" id="first_section">
                    <div class="container margin_60_40">
                        <div class="main_title center">
                            <span><em></em></span>
                            <h2>Popular Categories</h2>
                            <p>Discover Popular Artist Booking Categories</p>
                        </div>

                        <div class="owl-carousel owl-theme categories_carousel">
                            {this.renderCatItem(data)}                     
                            
                        </div>

                    </div>

                </div>
            );
        }
    }

    render() {

        let renderCat = ((this.state.loading == false) ? PopCategory.renderCat(this.state.data) : '');

        return (
            <>
                {renderCat}
            </>);
    }
}
