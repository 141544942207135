import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";

export class PopArtists extends Component {

    static propTypes = {
        pageType: PropTypes.string,

    };


    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,

        };

        this.handleLikeClick = this.handleLikeClick.bind(this);
      
    }


    handleLikeClick = async (e, item, idx) => {

        var isLike = this.state.data[idx].isLiked;

        const response = await fetch('v1/api/user/toggleWishlist', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "ArtistId": item.id,
                "isLike": !isLike
            })
        });
        const data = await response.json();
        if (data.status) {

            item.isLiked = data.data;
            this.state.data[idx] = item;
            var data2 = this.state.data;
            this.setState({
                data: data2
            })


        } else {
            //

        }
    };


    componentDidMount() {

        this.populateArtists();


    }

    async populateArtists() {
        const response = await fetch('v1/api/home/artists/search', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();        
        this.setState({ data: data.items, loading: false });

       
        
    }

    renderAritstItems(dataItem) {
        
        return dataItem?.map((item, idx) => { // here
            return (
                <>

                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6" data-key={idx}>
                        <div class="strip">
                            <figure>
                                <a href="javascript:void(0);" onClick={(event) => this.handleLikeClick(event, item, idx)} data-id={item.id} class="wish_bt"><i class={item.isLiked ? "text-danger icon_heart" : "icon_heart"} ></i></a>
                                <img src={item.imageUrl} class="img-fluid" alt={item.title} />
                                <a href={item.url} class="strip_info">
                                    <div class="item_title">
                                        <h3>{item.title}</h3>
                                        <small class="text_wrap truncate pop_txt"><span dangerouslySetInnerHTML={{ __html: (item.bio) }}></span></small>
                                    </div>
                                </a>
                            </figure>
                            {/*<ul>
                            <li><a href="#0" class="tooltip-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Available Appointment"><i class="icon-users"></i></a></li>
                            <li><a href="#0" class="tooltip-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Available Chat"><i class="icon-chat"></i></a></li>
                            <li>
                                <div class="score"><span>Superb<em>350 Reviews</em></span><strong>8.9</strong></div>
                            </li>
                        </ul>*/}
                        </div>
                    </div>              


                </>
            );
        });
    }

    renderAritsts(data) {
              
        
        if (data != null && data.length > 0) {

            return (
                <div class="container margin_60_40">
                    <div class="main_title center">
                        <span><em></em></span>
                        <h2>Popular Artists</h2>
                        <p>Elevate Your Event with these Popular Artists</p>
                    </div>
                    <div class="row add_bottom_15">
                        {this.renderAritstItems(data)}                   

                    </div>

                    <p class="text-center add_bottom_30"><a href="/search" class="btn_1 medium">Start Searching</a></p>

                </div>
            );
        }
    }

    render() {

        let renderCat = ((this.state.loading == false) ? this.renderAritsts(this.state.data) : '');

        return (
            <>
                {renderCat}
            </>);
    }
}
