import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SeoMeta } from './Layout/SeoMeta';
import $, { param } from 'jquery';

export class ContactCms extends Component {
    static displayName = ContactCms.name;


    static propTypes = {
        pageType: PropTypes.string,

    };


    constructor(props) {
        super(props);
        this.state = {
            data: {},
            status: false,
            email: '',
            name: '',
            phone: '',
            message:'',
            IsFormSubmitted: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }


    async handleSubmit(event) {

        var formData = {

            "Email": this.state.email,
            "Name": this.state.name,
            "Phone": this.state.phone,
            "Message": this.state.message

        };

        $.ajax({
            url: 'v1/api/user/contacts',
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            type: 'POST',

            success: (data) => {

                if (data.status) {

                    this.setState({
                        IsFormSubmitted: true,
                        status: true
                    }, function () {

                    });


                } else {

                }
            },

            error: (xhr, status, err) => {
                console.error(status, err.toString()); // eslint-disable-line
            },
        });


        event.preventDefault();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }



    componentDidMount() {



        this.populateWeatherData();
    }

    async populateWeatherData() {
        const response = await fetch('v1/api/cmsPage?pageType=' + this.props.pageType, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        if (data.status) {
            this.setState({ data: data.data, loading: false });
        } else {
            //
            this.setState({ data: null, loading: false });
        }
    }

    static featureImage(data) {
        return (<div class="hero_single inner_pages background-image" style={{ backgroundImage: `url(${data?.featureImage})` }} >
            <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.7)">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-md-8">
                            <h1>{data?.title}</h1>
                            <p>{data?.subTitle}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    static pageBody(data) {
        return (
            <>
                <div class="bg_gray">
                    <div class="container margin_60_40" dangerouslySetInnerHTML={{ __html: data?.content }}>

                    </div>
                </div>
            </>
        );
    }


    render() {

        let banner = this.state.loading ? "" : ContactCms.featureImage(this.state.data);
        let page = this.state.loading ? "" : ContactCms.pageBody(this.state.data);
        const showComfirm = !this.state.IsFormSubmitted;


        return (
            <>
                <SeoMeta pageType={this.props.pageType} />
                {banner}
                {page}
                <div class="container margin_60_40">
                    <h5 class="mb_5">Drop Us a Line</h5>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 add_bottom_25">
                            <div id="message-contact"></div>

                            {
                                showComfirm ? (<>

                                    <form onSubmit={this.handleSubmit} >
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Name" id="name_contact" value={this.state.name} onChange={this.handleInputChange} name="name" />
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="email" placeholder="Email" id="email_contact" value={this.state.email} onChange={this.handleInputChange} name="email" />
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" type="text" placeholder="Phone" id="phone_contact" value={this.state.phone} onChange={this.handleInputChange} name="phone" />
                                        </div>
                                        <div class="form-group">
                                            <textarea class="form-control" placeholder="Message" id="message_contact" value={this.state.message} onChange={this.handleInputChange} name="message" ></textarea>
                                        </div>

                                        <div class="form-group">
                                            <input class="btn_1 full-width" type="submit" value="Submit" id="submit-contact" />
                                        </div>
                                    </form>

                                </>) : <p class='text-success'>{this.state.status ? 'Thanks for contacting, We will get back to you shortly!!' : 'Error !'}</p>

                            }

                           
                        </div>
                        <div class="col-lg-8 col-md-6 add_bottom_25">
                            <iframe class="map_contact" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15079.489768963489!2d72.86932185926945!3d19.113251067826983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8044d09f2dd%3A0x7d1f20e8ce256866!2s9X%20Media%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1685551569333!5m2!1sen!2sin" allowfullscreen=""></iframe>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
