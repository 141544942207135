import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../sass/booking-sign_up.scss';
import $, { param } from 'jquery';
import { SeoMeta } from './Layout/SeoMeta';
import ReactGA from 'react-ga';
export class Booking extends Component {
    static displayName = Booking.name;



    static propTypes = {
        pageType: PropTypes.string,

    };


    constructor(props) {
        super(props);
        this.state = {
            artistItem: [],
            loading: true,
            name: '',
            email: '',
            phone: '',
            occasion: '',
            venue: '',
            budget: '',
            noOfGuest: '',
            notes: '',
            artistId: '',
            bookingDate: '',
            sendMore: true,
            IsFormSubmitted: false,
            booking: {}
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }


    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        var id = query.get('artistId');
        var date = query.get('bookingDate');

        this.populateArtistData(id, date);

    }

    async populateArtistData(id, date) {
        const response = await fetch('v1/api/booking/ArtistById', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "Id": id
            })
        });
        const data = await response.json();
        if (data.status) {
            this.setState({ artistItem: data.data, loading: false, artistId: data.data.id, bookingDate: date });
        } else {
            //
            this.setState({ artistItem: null, loading: false });
        }
    }

    async handleSubmit(event) {

        var formData = {
            "Id": 0,
            "ArtistId": this.state.artistId,
            "UserId": "",
            "Name": this.state.name,
            "Phone": this.state.phone,
            "Email": this.state.email,
            "BookingDate": this.state.bookingDate,
            "EventType": this.state.occasion,
            "VenueAddress": this.state.venue,
            "Budget": Number(this.state.budget),
            "AttendCount": Number(this.state.noOfGuest),
            "Description": this.state.notes,
            "SendMoreSug": this.state.sendMore
        };

        $.ajax({
            url: 'v1/api/booking/create',
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            type: 'POST',

            success: (data) => {

                if (data.status) {

                    this.setState({
                        IsFormSubmitted: true,
                        booking: data.data,
                        name: '',
                        email: '',
                        phone: '',
                        occasion: '',
                        venue: '',
                        budget: '',
                        noOfGuest: '',
                        notes: '',
                        artistId: '',
                        bookingDate: '',
                        sendMore: true,

                    }, function () {
                       
                    });

                    ReactGA.event({
                        category: 'Booking',
                        action: 'Created New Booking'
                    });


                } else {

                }
            },

            error: (xhr, status, err) => {
                console.error(status, err.toString()); // eslint-disable-line
            },
        });


        event.preventDefault();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    render() {

        const artistItem = this.state.artistItem;
        const booking = this.state.booking;
        const showComfirm = !this.state.IsFormSubmitted;

        if (showComfirm) {

            return (
                <>
                    <SeoMeta pageType={this.props.pageType} />
                    <form onSubmit={this.handleSubmit}>
                        <input type="hidden" value={this.state.artistId} name="artistId" />
                        <div class="container margin_60_40">
                            <div class="row justify-content-center">
                                <div class="col-lg-6">
                                    <div class="box_booking_2">
                                        <div class="head">
                                            <div class="user_thumb">
                                                <figure><img src={artistItem.profileImage} alt={artistItem.name} /></figure>

                                            </div>
                                            <div class="title">
                                                <h3>{artistItem.name}</h3>
                                                <p>{artistItem.city + '-' + artistItem.country}</p>
                                            </div>
                                        </div>

                                        <div class="main">
                                            <h6>Booking summary</h6>
                                            <ul>
                                                <input type="hidden" value={this.state.bookingDate} name="date" />
                                                <li>Date<span>{this.state.bookingDate}</span></li>
                                                <li>Type<span>New Booking</span></li>
                                            </ul>
                                            <hr />
                                            <h6>Personal details</h6>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <input class="form-control" placeholder="First and Last Name" value={this.state.name} onChange={this.handleInputChange} name="name" required />
                                                        <i class="icon_pencil"></i>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <input class="form-control" placeholder="Email Address" value={this.state.email} onChange={this.handleInputChange} name="email" required  type="email"/>
                                                        <i class="icon_mail"></i>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group add_bottom_15">
                                                        <input class="form-control" placeholder="Phone" value={this.state.phone} onChange={this.handleInputChange} name="phone" required />
                                                        <i class="icon_phone"></i>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group add_bottom_15">
                                                        <input class="form-control" placeholder="Occasion" value={this.state.occasion} onChange={this.handleInputChange} name="occasion" required />
                                                        <i class="icon_calendar"></i>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group add_bottom_15">
                                                        <input class="form-control" placeholder="Venue" value={this.state.venue} onChange={this.handleInputChange} name="venue" />
                                                        <i class="icon_pin"></i>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group add_bottom_15">
                                                        <input class="form-control" placeholder="Budget" value={this.state.budget} onChange={this.handleInputChange} name="budget" />
                                                        <i class="icon_currency"></i>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group add_bottom_15">
                                                        <input class="form-control" placeholder="No Of guests" value={this.state.noOfGuest} onChange={this.handleInputChange} name="noOfGuest" />
                                                        <i class="icon_group"></i>
                                                    </div>
                                                </div>
                                                <div class="form-group add_bottom_15">
                                                    <textarea class="form-control" placeholder="Notes" rows="10" value={this.state.notes} onChange={this.handleInputChange} name="notes" >
                                                    </textarea>
                                                    <i class="icon_pencil"></i>
                                                </div></div>

                                            <input type="submit" class="btn_1 full-width mb_5" value="Submit" />

                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>
                    </form>
                </>
            );
        } else {

            return (<>

                <div class="container margin_60_40">
                    <div class="row justify-content-center">
                        <div class="col-lg-4">
                            <div class="box_booking_2">
                                <div class="head">
                                    <div class="user_thumb">
                                        <figure><img src={artistItem.profileImage} alt={artistItem.name} /></figure>

                                    </div>
                                    <div class="title">
                                        <h3>{artistItem.name}</h3>
                                        <p>{artistItem.city + '-' + artistItem.country}</p>
                                    </div>
                                </div>
                                
                                <div class="main">
                                    <div id="confirm">
                                        <div class="icon icon--order-success svg add_bottom_15">
                                            <img src="img/confirm.svg" />
                                        </div>
                                        <h3>Booking Submited!</h3>
                                        <h3>Booking Id: {booking.id}</h3>
                                        <p>We will reply shortly to confirm the artist.</p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        
                    </div>
                   
                </div>

            </>);
        }
    }
}
