import React, { Component, useState } from 'react';
import '../sass/listing.scss';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import $, { param } from 'jquery';
import { SeoMeta } from './Layout/SeoMeta';
import { Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";

export class CustomListing extends Component {

    static propTypes = {
        pageType: PropTypes.string,

    };


    static displayName = CustomListing.name;

    static url = "v1/api/search";
    static author = "";
    static perPage = "20";

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            pageData: [],
            offset: 0,
            url: "v1/api/search",
            author: "",
            perPage: "18",
            currentPage: 1,
            searchTerm: "",
            totalPages: 0,
            filters: [],
            selectedFilters: [],
            showCategoryFilter: true,
            showEventFilter: true,
            showLanguageFilter: true,
            showLocationFilter: true,
            pageBaseUrl: '',
            catMsg: '',
            seoPrefix: '',
            breadcrums: [],
            hasMore: true
        };
        this.state.breadcrums.push({ title: 'Home', url: '/' });
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.handleLikeClick = this.handleLikeClick.bind(this);
        this.loadArtistsFromServer = this.loadArtistsFromServer.bind(this);
    }

    loadArtistsFromServer() {


        $.ajax({
            url: this.state.url,
            data: { pageSize: this.state.perPage, pageNumber: this.state.offset + 1, searchString: this.state.searchTerm, currentFilter: this.state.selectedFilters.join(",") },
            dataType: 'json',
            type: 'GET',

            success: (data) => {
                this.setState({
                    data: data.items.length > 0 ? this.state.data.concat(data.items) : [],
                    pageCount: data.totalPages,
                    offset: data.pageIndex,
                    currentPage: data.pageIndex - 1,
                    totalPages: data.totalPages,
                    hasMore: (data.totalPages > 0 ? (data.pageIndex < data.totalPages) : false)
                });

                if (this.state.totalPages == 0) {
                    this.state.offset = 0;
                    this.state.currentPage = 1;
                    this.state.searchTerm = "";
                }
            },

            error: (xhr, status, err) => {
                console.error(this.state.url, status, err.toString()); // eslint-disable-line
            },
        });
    }


    componentDidMount() {

        const script = document.createElement("script");
        script.src = "/js/sticky_sidebar.min.js";
        script.async = true;
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "/js/specific_listing.min.js";
        script2.async = true;
        document.body.appendChild(script2);

        const query = new URLSearchParams(window.location.search);
        var q = query.get('q');
        var offset = query.get('page');
        var cat = window.location.pathname.split("/").pop();
        var tags = window.location.hash.substr(1);


        if (this.props.pageType == "CustomPageList") {

            var pageUrl = window.location.pathname.split("/").pop();
            this.loadByCustomPage(pageUrl);

        }


       

        this.state.pageBaseUrl = window.location.href.split('?')[0]

       

        //this.GetFilters();

    }


    async loadByCustomPage(pageUrl) {

        const response = await fetch('/v1/api/customPage?pageUrl=' + pageUrl, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
            /*body: JSON.stringify({				
            })*/
        });
        const data = await response.json();

        if (data.status) {

            this.setState({
                pageData: data.data, loadingMenu: false

            }, () => {
          
                    debugger
                    var searchFilters = data.data.searchFilters;
                    const query = new URLSearchParams(searchFilters);
                    var q = query.get('q');
                    var offset = query.get('page');
                    var filters = query.get('filters');
                    //var cat = window.location.pathname.split("/").pop();
                    //var tags = window.location.hash.substr(1);                    

                    debugger
                    var filtersArr = filters.split(",");
                    var loc = null;
                    for (const filter of filtersArr) {
                        if (filter.startsWith("lo__")) {
                            loc = ((loc == null) ? filter.replace("lo__", "") : loc + "," + filter.replace("lo__", ""));
                        }
                    }
                    if (loc != null) {
                        var cat = loc;
                        this.state.showLanguageFilter = false;
                        this.state.seoPrefix = cat.replace(/%20/g, " ").replace(/_/g, "/");
                        this.state.catMsg = data.data.title;
                        this.loadByFilter(filters);
                        this.state.breadcrums.push({ title: 'Pages', url: '/pages' });
                        this.state.breadcrums.push({ title: data.data.title, url: "/pages/" + data.data.pageUrl });
                        this.populateSeo(data.data.pageId);
                    }

                    
                

            });
        } else {

            alert('404');
        }
    }

    loadByFilter(filterValue) {
        
        if (filterValue) {
            this.state.selectedFilters.push(filterValue);
        } else {

            const index = this.state.selectedFilters.indexOf(filterValue);
            if (index > -1) {
                this.state.selectedFilters.splice(index, 1);
            }
        }
        this.loadArtistsFromServer();
        
    }
    

    handlePageClick = (data) => {
        console.log('onPageChange', data);
        let selected = data.selected;
        let offset = selected;

        this.setState({ offset: offset }, () => {
            this.loadArtistsFromServer();
            this.updateWindowsUrl();
        });
    };

    handleSearchDebounced = debounce(function () {
        console.log(this.state.searchTerm);
        this.loadArtistsFromServer();
        this.updateWindowsUrl();
    }, 1000);



    handleChange = (e) => {
        this.state.offset = 0;
        this.state.currentPage = 1;
        this.setState({ searchTerm: e.target.value });
        this.handleSearchDebounced();
    };

    handleChangeFilter = (e) => {
        if (e.target.checked) {

            this.state.selectedFilters.push(e.target.value);


        } else {

            const index = this.state.selectedFilters.indexOf(e.target.value);
            if (index > -1) {
                this.state.selectedFilters.splice(index, 1);
            }
        }
        this.loadArtistsFromServer();
        this.updateWindowsUrl();

        //return false;
    };

    handleLikeClick = async (e, item, idx) => {

        var isLike = this.state.data[idx].isLiked;

        const response = await fetch('v1/api/user/toggleWishlist', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "ArtistId": item.id,
                "isLike": !isLike
            })
        });
        const data = await response.json();
        if (data.status) {

            item.isLiked = data.data;
            this.state.data[idx] = item;
            var data2 = this.state.data;
            this.setState({
                data : data2
            })
           

        } else {
            //
            
        }
    };

    handleFilterSubmit = (e) => {
        this.loadArtistsFromServer();

        e.preventDefault();
        return false;
    };

    updateWindowsUrl = () => {


        var q = this.state.searchTerm;
        var offset = this.state.offset;
        var filters = this.state.selectedFilters;

        var params = [];
        if (q != "" || q != null) {
            params.push("q=" + q);
        }
        if (offset >= 0) {
            params.push("page=" + (Number(offset) + 1));
        }
        if (filters.length > 0) {
            params.push("filters=" + filters.join(","));
        }

        console.log("Params " + params);
        if (params.length > 0) {
            window.history.replaceState(null, "Search & Pagination", this.state.pageBaseUrl + "?" + params.join('&'));
        }


    };


    async GetFilters() {

        $.ajax({
            url: 'v1/api/Filters',
            data: {},
            dataType: 'json',
            type: 'POST',

            success: (data) => {
                this.setState({ filters: data.data, loading: false });
            },

            error: (xhr, status, err) => {
                console.error(this.state.url, status, err.toString()); // eslint-disable-line
            },
        });
    }


    renderCheckboxes(checkboxes, prefix) {
        return checkboxes?.map((item, idx) => { // here
            return (
                <>
                    <li><label class="container_check">{item.name}<input type="checkbox" value={prefix + '__' + item.name} onChange={(event) => this.handleChangeFilter(event)} /><span class="checkmark"></span></label></li>
                </>
            );
        });
    }

    renderFilter(title, data, prefix, showFilter) {

        return showFilter ? (<div class="filter_type" >
            <h4><a href={'#filter_' + prefix} data-bs-toggle="collapse" class="opened">{title}</a></h4>
            <div class="collapse show" id={'filter_' + prefix} >
                <ul>
                    {this.renderCheckboxes(data, prefix)}
                </ul>
            </div>

        </div>) : "";

    }

    renderArtistItems(data) {

        return data != null && data.length > 0 && data?.map((item, idx) => { // here
            return (
                <>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6" data-key={idx}>
                        <div class="strip">
                            <figure>
                                <a href="javascript:void(0);" onClick={(event) => this.handleLikeClick(event, item, idx)} data-id={item.id} class="wish_bt"><i class={item.isLiked ? "text-danger icon_heart" : "icon_heart"} ></i></a>
                                <img src={item.imageUrl} class="img-fluid" alt={item.title} />
                                <a href={item.url} class="strip_info">
                                    <div class="item_title">
                                        <h3>{item.title}</h3>
                                        <small class="text_wrap truncate"><span dangerouslySetInnerHTML={{ __html: (item.bio) }}></span></small>
                                    </div>
                                </a>
                            </figure>
                           
                        </div>
                    </div>                    
                </>
            );
        });
    }

    renderAritstList(data) {

        return (
            <div class="row grid_sidebar">
                {this.renderArtistItems(data)}
            </div>
        );
    }

    renderBreadcrumItems(data) {

        return data?.map((item, idx) => { // here
            return (
                <>
                    <li><a href={item.url}>{item.title}</a></li>
                </>
            );
        });
    }

    renderBreadcrums(data) {

        return (
            <ul class='float-end'>{this.renderBreadcrumItems(data)}</ul>
        );
    }

    async populateSeo(pageId) {
        const response = await fetch('/v1/api/cmsSeo?pageType=' + pageId, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }

        });
        const data = await response.json();

        this.setState({ seo: data.data });
    }

    static MetaData(seoData) {

        const currentURL = window.location.href

        if (seoData != null) {

            return (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{seoData.seoTitle}</title>
                    <link rel="canonical" href={currentURL} />
                    <meta name="title" content={seoData.seoTitle} />
                    <meta name="keywords" content={seoData.keywords} />
                    <meta name="description" content={seoData.metaDescription} />
                </Helmet>
            );
        }
    }

    static featureImage(data) {
        return (data?.featureImage != null && data?.featureImage != "")  ? (<div class="hero_single inner_pages background-image" style={{ backgroundImage: `url(${data?.featureImage})` }} >
            <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.7)">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-md-8">
                            <h1>{data?.title}</h1>
                            <p>{data?.subTitle}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>): "";
    }

    render() {

        let banner = this.state.loading ? "" : CustomListing.featureImage(this.state.pageData);
        let seoHeaders = this.state.loading ? "" : CustomListing.MetaData(this.state.seo);

        return (
            <>
                {seoHeaders}
                {banner}
                <div class="filters_full version_2">
                    <div class="container clearfix">
                        <div class="sort_select">
                            <select name="sort" id="sort">
                                <option value="popularity" selected="selected">Sort by Popularity</option>
                                <option value="rating">Sort by Average rating</option>
                                <option value="date">Sort by newness</option>
                            </select>
                        </div>
                       {/* <a href="javascript:void(0);" class="open_filters btn_filters"><i class="icon_adjust-vert"></i><span>Filters</span></a>*/}

                        <div class="search_bar_list">
                            <input type="text" class="form-control" placeholder="Search..." value={this.state.searchTerm} onChange={this.handleChange} />
                        </div>
                        <a class="btn_search_mobile btn_filters" data-bs-toggle="collapse" href="#collapseSearch"><i class="icon_search"></i></a>
                    </div>



                    <div class="collapse" id="collapseSearch">
                        <div class="search_bar_list">
                            <input type="text" class="form-control" placeholder="Search again..." />
                        </div>
                    </div>

                </div>

                <div class="container margin_30_40">
                    <div class="page_header breadcrumbs">
                        <h1>{this.state.catMsg}</h1>

                        {this.renderBreadcrums(this.state.breadcrums)}
                    </div>
                    <div class="row">
                        

                        <div class="col-lg-12">

                           {this.renderAritstList(this.state.data)}

                            <div class="pagination_fg">
                                <ReactPaginate
                                    previousLabel="previous"
                                    nextLabel="next"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={this.state.totalPages}
                                    pageRangeDisplayed={4}
                                    marginPagesDisplayed={2}
                                    onPageChange={this.handlePageClick}
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                    // eslint-disable-next-line no-unused-vars
                                    hrefBuilder={(page, pageCount, selected) =>
                                        page >= 1 && page <= pageCount ? `/book-an-artist/${page}` : '#'
                                    }
                                    hrefAllControls
                                    forcePage={this.state.currentPage}
                                    renderOnZeroPageCount={null}
                                    onClick={(clickEvent) => {
                                        console.log('onClick', clickEvent);
                                        // Return false to prevent standard page change,
                                        // return false; // --> Will do nothing.
                                        // return a number to choose the next page,
                                        // return 4; --> Will go to page 5 (index 4)
                                        // return nothing (undefined) to let standard behavior take place.
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>


            </>
        );
    }
}
