import React, { Component } from 'react';
import { NavMenu } from './Layout/NavMenu';
import { Footer } from './Layout/Footer';
import { Audio } from 'react-loader-spinner';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import ReactGA from 'react-ga';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {

        ReactGA.initialize('G-PF4K8JH15L');
        ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <>          
        <ReactNotifications />
        <NavMenu />          
            <main class="bg_color">
                 {this.props.children}
            </main>           
        <Footer />
        </>
    );
  }
}
