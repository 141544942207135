import React, { Component, useState } from 'react';
import '../sass/listing.scss';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import $, { param } from 'jquery';
import { SeoMeta } from './Layout/SeoMeta';

export class Wishlist extends Component {
    static displayName = Wishlist.name;

    static propTypes = {
        pageType: PropTypes.string,

    };


    static url = "v1/api/search/wishlist";
    static author = "";
    static perPage = "20";

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            offset: 0,
            url: "v1/api/search/wishlist",
            author: "",
            perPage: "18",
            currentPage: 1,
            searchTerm: "",
            totalPages: 0,
            filters: [],
            selectedFilters: [],
            showCategoryFilter: true,
            showEventFilter: true,
            showLanguageFilter: true,
            showLocationFilter: true,
            pageBaseUrl: ''
        };

       
        this.handleLikeClick = this.handleLikeClick.bind(this);
    }

    componentDidMount() {


        const query = new URLSearchParams(window.location.search);
        var q = query.get('q');
        var offset = query.get('page');
        this.state.pageBaseUrl = window.location.href.split('?')[0]

        this.setState({ searchTerm: q, offset: (offset != null ? Number(offset) - 1 : 0) }, () => {
            this.loadArtistsFromServer();
        });

      
    }


    loadArtistsFromServer() {


        $.ajax({
            url: this.state.url,
            data: { pageSize: this.state.perPage, pageNumber: this.state.offset + 1, searchString: this.state.searchTerm, currentFilter: this.state.selectedFilters.join(",") },
            dataType: 'json',
            type: 'GET',

            success: (data) => {
                this.setState({
                    data: data.items,
                    pageCount: data.totalPages,
                    currentPage: data.pageIndex - 1,
                    totalPages: data.totalPages
                });


            },

            error: (xhr, status, err) => {
                console.error(this.state.url, status, err.toString()); // eslint-disable-line
            },
        });
    }


    handlePageClick = (data) => {
        console.log('onPageChange', data);
        let selected = data.selected;
        let offset = selected;

        this.setState({ offset: offset }, () => {
            this.loadArtistsFromServer();
            this.updateWindowsUrl();
        });
    };


    handleLikeClick = async (e, item, idx) => {

        var isLike = this.state.data[idx].isLiked;

        const response = await fetch('v1/api/user/toggleWishlist', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "ArtistId": item.id,
                "isLike": !isLike
            })
        });
        const data = await response.json();
        if (data.status) {

            this.loadArtistsFromServer();

        } else {
            //

        }
    };


    updateWindowsUrl = () => {


        var q = this.state.searchTerm;
        var offset = this.state.offset;
        var filters = this.state.selectedFilters;

        var params = [];
        if (q != "" || q != null) {
            params.push("q=" + q);
        }
        if (offset >= 0) {
            params.push("page=" + (Number(offset) + 1));
        }
        if (filters.length > 0) {
            params.push("filters=" + filters.join(","));
        }

        console.log("Params " + params);
        if (params.length > 0) {
            window.history.replaceState(null, "Search & Pagination", this.state.pageBaseUrl + "?" + params.join('&'));
        }


    };

    renderArtistItems(data) {

        return data?.map((item, idx) => { // here
            return (
                <>
                    <div class="col-xl-4 col-lg-6 col-md-6">
                        <div class="strip">
                            <figure>
                                <a href="javascript:void(0);" onClick={(event) => this.handleLikeClick(event, item, idx)} data-id={item.id} class="wish_bt"><i class={item.isLiked ? "text-danger icon_heart" : "icon_heart"} ></i></a>
                                <img src={item.imageUrl} class="img-fluid" alt={item.title} />
                                <a href={item.url} class="strip_info">
                                    <div class="item_title">
                                        <h3>{item.title}</h3>
                                        <small class="text_wrap truncate"><span dangerouslySetInnerHTML={{ __html: (item.bio) }}></span></small>
                                    </div>
                                </a>
                            </figure>                            
                        </div>
                    </div>
                </>
            );
        });
    }

    renderAritstList(data) {

        return (
            <div class="row grid_sidebar">
                {this.renderArtistItems(data)}
            </div>
        );
    }



    render() {
        return (
            <>
                <SeoMeta pageType={this.props.pageType} />
                <div class="hero_single inner_pages background-image" data-background="url(img/hero_general.jpg)">
                    <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.7)">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-xl-9 col-lg-10 col-md-8">
                                    <h1>Your Wishlist</h1>
                                    <p>Foogra a successful restaurant experience</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
               
                <div class="container margin_60_30">
                <div class="main_title center">
                    <span><em></em></span>
                    <h2>Listed Professionals</h2>
                    <p>Cum doctus civibus efficiantur in imperdiet deterruisset</p>
                </div>
                <div class="row">

                    {this.renderAritstList(this.state.data)}

                    <div class="pagination_fg">
                        <ReactPaginate
                            previousLabel="previous"
                            nextLabel="next"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={this.state.totalPages}
                            pageRangeDisplayed={4}
                            marginPagesDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            activeClassName="active"
                            // eslint-disable-next-line no-unused-vars
                            hrefBuilder={(page, pageCount, selected) =>
                                page >= 1 && page <= pageCount ? `/wishlist/${page}` : '#'
                            }
                            hrefAllControls
                            forcePage={this.state.currentPage}
                            renderOnZeroPageCount={null}
                            onClick={(clickEvent) => {
                                console.log('onClick', clickEvent);
                                // Return false to prevent standard page change,
                                // return false; // --> Will do nothing.
                                // return a number to choose the next page,
                                // return 4; --> Will go to page 5 (index 4)
                                // return nothing (undefined) to let standard behavior take place.
                            }}
                        />
                    </div>
                    </div>
                </div>

            </>
        );
    }
}
