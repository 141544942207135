import React, { Component } from 'react';
import '../sass/submit.scss'
import $, { param } from 'jquery';
import { SeoMeta } from './Layout/SeoMeta';
import ReactGA from 'react-ga';
import { getSelectedData, getSelectedDataArray } from './Utility/custom'



export class JoinNow extends Component {
    static displayName = JoinNow.name;

    constructor(props) {
        super(props);
        this.state = {
            Email: "",
            Name: "",
            Profession: "",
            PhoneNumber: "",
            Address: "",
            City: "",
			Country: "",
			Gender: "",
			DateOfBirth: "",
			Description: "",
			Biography: "",
			Phone: "",
			Website: "",
			FacebookLink: "",
			TwitterLink: "",
			LinkedLink: "",
			Address1: "",
			Address2: "",
			City: "",
			PostalCode: "",
			State: "",
			OffStageMembers: "",
			PerformingMembers: "",
			PerformanceDuration: "",
			CanTravel: "",
			MinBudget: "",
			MaxBudget: "",
			Skills: "",
			Languages: "",
			Event: "",
			Tags: "",
			Genres:"",

            loading: true,
			IsFormSubmitted: false,
			IsFormSubmittedStep2 : false,
            Status: "",
            errors: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmitStep2 = this.handleSubmitStep2.bind(this);
	
	}

	componentDidMount() {

		const script = document.createElement("script");
		script.src = "/lib/select2/js/select2.full.min.js";
		script.async = false;
		document.body.appendChild(script);

		const script1 = document.createElement("script");
		script1.src = "/lib/tagify/jquery.tagify.min.js";
		script1.async = false;
		document.body.appendChild(script1);

		const script2 = document.createElement("script");
		script2.src = "/lib/tagify/tagify.polyfills.min.js";
		script2.async = false;
		document.body.appendChild(script2);

		const script3 = document.createElement("script");
		script3.src = "/js/artist_custom.js";
		script3.async = false;
		document.body.appendChild(script3);


		
	}

    async handleSubmit(event) {

       

        var formData = {
            "Email": this.state.Email,
            "Name": this.state.Name,
            "Profession": this.state.Profession,
            "Address": this.state.Address,
            "City": this.state.City,
            "Country": this.state.Country,
            "PhoneNumber": this.state.PhoneNumber
        };

        $.ajax({
            url: 'v1/api/user/joinus',
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            type: 'POST',

            success: (data) => {

                if (data.status) {

                    this.setState({
                        IsFormSubmitted: true,
						Status: data.data,
						Phone: this.state.PhoneNumber,
						Address1: this.state.Address,						
                       /* Name: "",
                        Profession: "",
                        Address: "",
                        City: "",
                        Country: "",
                        PhoneNumber:"",*/
                        errors: []

                    }, function () {


                    });

                    ReactGA.event({
                        category: 'JoinNow-Step1',
                        action: 'Created an Artists Step1'
                    });

					const script3 = document.createElement("script");
					script3.src = "/js/artist_custom.js";
					script3.async = false;
					document.body.appendChild(script3);

                } else {

                    this.setState({
                        errors:  data.data.errors
                    }, function () {


                    });

                  

                }

            },

            error: (xhr, status, err) => {
                console.error(status, err.toString()); // eslint-disable-line
            },
        });

       
        event.preventDefault();
	}



	async handleSubmitStep2(event) {

		var City = getSelectedData($('#city_dd').find(':selected'));
		var State = getSelectedData($('#state_dd').find(':selected'));
		var Country = getSelectedData($('#country_dd').find(':selected'));
		var Skills = getSelectedDataArray($('#skills_dd').find(':selected'));
		var Languages = getSelectedDataArray($('#language_dd').find(':selected'));
		var EventDD = getSelectedDataArray($('#events_dd').find(':selected'));
		var Tags = getSelectedDataArray($('#tags_dd').find(':selected'));
		var Genres = getSelectedDataArray($('#genre_dd').find(':selected'));


		var formData = {
			Email: this.state.Email,
			Name: this.state.Name,			
			Address: this.state.Address,
			City: City,
			State: State,
			Country: Country,
			Gender: this.state.Gender,
			DateOfBirth: this.state.DateOfBirth,
			Description: this.state.Description,
			Biography: this.state.Biography,
			Phone: this.state.Phone,
			Website: this.state.Website,
			FacebookLink: this.state.FacebookLink,
			TwitterLink: this.state.TwitterLink,
			LinkedLink: this.state.LinkedLink,
			Address1: this.state.Address1,
			Address2: this.state.Address2,
			City: this.state.City,
			PostalCode: this.state.PostalCode,
			State: this.state.State,
			OffStageMembers: this.state.OffStageMembers,
			PerformingMembers: this.state.PerformingMembers,
			PerformanceDuration: this.state.PerformanceDuration,
			CanTravel: this.state.CanTravel,
			MinBudget: this.state.MinBudget,
			MaxBudget: this.state.MaxBudget,
			Skills: Skills,
			Languages: Languages,
			Event: EventDD,
			Tags: Tags,
			Genres: Genres,
			OwnerID: this.state.OwnerID,
		}

		$.ajax({
			url: 'v1/api/createArtist',
			data: JSON.stringify(formData),
			dataType: 'json',
			contentType: "application/json; charset=utf-8",
			type: 'POST',

			success: (data) => {

				if (data.status) {

					this.setState({
						IsFormSubmittedStep2: true,
						Status: data.data,
						/*Name: "",
						Profession: "",
						Address: "",
						City: "",
						Country: "",
						PhoneNumber: "",*/
						errors: []

					}, function () {


					});

					ReactGA.event({
						category: 'JoinNow-Step2',
						action: 'Created an Artists Step2'
					});



				} else {

					this.setState({
						errors: data.data.errors
					}, function () {


					});



				}

			},

			error: (xhr, status, err) => {
				console.log(status, err.toString()); // eslint-disable-line
				var error = JSON.parse(xhr.responseText);
				alert(error);
				console.log(error);
				this.setState({
					errors: error.errors
				}, function () {


				});
			},
		});


		event.preventDefault();
	}

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    render() {

		const showComfirm = !this.state.IsFormSubmitted;
		const showStep2 = !this.state.IsFormSubmittedStep2;
        const errors = this.state.errors;

		if (showComfirm) {

			return (
				<>
					<SeoMeta pageType={this.props.pageType} />
					<div class="bg_gray pattern_mail" id="submit">
						<div class="container margin_60_40">
							<div class="row justify-content-center">
								<div class="col-lg-5">
									<div class="box_general padding">
										<div class="text-center add_bottom_15">
											<h4>Please fill the form below</h4>
											<p>Register With Us And Get Access To Performance Opportunities That Will Help You Showcase Your Art And Get Paid For It.</p>
										</div>
										<div id="message-register"></div>
										<form onSubmit={this.handleSubmit} >
											<h6>Personal data</h6>
											<div class="row">
												<div class="col-lg-12">
													<div class="form-group">
														<input type="text" class="form-control" placeholder="Name and Last Name" name="Name" value={this.state.Name} onChange={this.handleInputChange} required />
													</div>
												</div>
											</div>

											<div class="row add_bottom_15">
												<div class="col-lg-6">
													<div class="form-group">
														<input type="email" class="form-control" placeholder="Email Address" name="Email" value={this.state.Email} onChange={this.handleInputChange} required />
													</div>
												</div>
												<div class="col-lg-6">
													<div class="form-group">
														<input type="text" class="form-control" placeholder="Phone Number" name="PhoneNumber" value={this.state.PhoneNumber} onChange={this.handleInputChange} required />
													</div>
												</div>
											</div>

											<h6>Professional info</h6>
											<div class="row">
												<div class="col-lg-12">
													<div class="form-group">
														<input type="text" class="form-control" placeholder="Your Profession" name="Profession" value={this.state.Profession} onChange={this.handleInputChange} required />
													</div>
												</div>
											</div>

											<div class="row">
												<div class="col-lg-12">
													<div class="form-group">
														<input type="text" class="form-control" placeholder="Address" name="Address" value={this.state.Address} onChange={this.handleInputChange} required />
													</div>
												</div>
											</div>

											<div class="row add_bottom_15">
												<div class="col-md-6">
													<div class="form-group">
														<input type="text" class="form-control" placeholder="City" name="City" value={this.state.City} onChange={this.handleInputChange} required />
													</div>
												</div>
												<div class="col-md-6">
													<div class="form-group">
														<select class="form-control" name="Country" value={this.state.Country} onChange={this.handleInputChange} required>
															<option value="">Country</option>
															<option value="India" selected>India</option>
														</select>
													</div>
												</div>
											</div>

											<h6>I am not a robot</h6>
											<div class="row add_bottom_25">
												<div class="col-lg-12">
													<div class="form-group">
														<input type="text" id="verify_register" class="form-control" placeholder="Human verify: 3 + 1 =?" />
													</div>
												</div>
											</div>

											<div class="text-center form-group"><input type="submit" class="btn_1" value="Submit" id="submit-register" /></div>
										</form>
										<div>
											<ul>
												{
													errors?.map(function (obj, i) {
														return <li class='list-group-item-danger'>{obj.description}</li>
													})
												}
											</ul>
										</div>
									</div>

								</div>
							</div>
						</div>

					</div>
				</>
			);

		} else if (showStep2) {

			return (<>

				<div class="bg_gray pattern_mail" id="submit">
					<div class="container margin_60_40">
						<div class="row justify-content-center">

							<form onSubmit={this.handleSubmitStep2} >


								<div class="box_general">
									<div class="header_box version_2">
										<h2><i class="fa fa-file"></i>Basic info</h2>
									</div>
									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<label class="control-label" for="Name">Name *</label>
												<input class="form-control" type="text" data-val="true" data-val-required="The Name field is required." id="Name" name="Name" value={this.state.Name} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="Name" data-valmsg-replace="true"></span>
											</div>
										</div>
										{/*	<div class="col-md-6">
								<div class="form-group">
									<label class="control-label" for="Keywords">Keywords</label>
									<input class="form-control" id="keywords_tags" type="text" name="Keywords" value={this.state.City} onChange={this.handleInputChange} tabindex="-1" />
										<span class="text-danger field-validation-valid" data-valmsg-for="Keywords" data-valmsg-replace="true"></span>
								</div>
							</div>*/}

										<div class="col-md-4">
											<div class="form-group">
												<label class="control-label" for="Gender">Gender *</label>
												<select class="form-control" data-val="true" data-val-required="The Gender field is required." id="Gender" name="Gender" value={this.state.Gender} onChange={this.handleInputChange} >
													<option value="">Select Gender</option>
													<option value="Male">Male</option>
													<option value="Female">Female</option>
													<option value="Other">Other</option>
												</select>
												<span class="text-danger field-validation-valid" data-valmsg-for="Gender" data-valmsg-replace="true"></span>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label class="control-label" for="DateOfBirth">DateOfBirth *</label>
												<input class="form-control" type="date" id="DateOfBirth" name="DateOfBirth" value={this.state.DateOfBirth} onChange={this.handleInputChange} required="true" />
												<span class="text-danger field-validation-valid" data-valmsg-for="DateOfBirth" data-valmsg-replace="true"></span>
											</div>
										</div>
									</div>



									{/*<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label class="control-label" for="Banner">Banner</label>
									<div id="upload_banner_dropzone" class="dropzone dz-clickable"><div class="dz-default dz-message"><span>Drop files here to upload</span></div></div>
									<div id="banner_file_list">
										<ul></ul>
									</div>
									<input class="form-control" type="hidden" id="banner_img" name="Banner" value={this.state.City} onChange={this.handleInputChange} />
									<span class="text-danger field-validation-valid" data-valmsg-for="Banner" data-valmsg-replace="true"></span>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label class="control-label" for="ProfileImage">ProfileImage</label>
									<div id="upload_profile_dropzone" class="dropzone dz-clickable"><div class="dz-default dz-message"><span>Drop files here to upload</span></div></div>
									<div id="profile_file_list">
										<ul></ul>
									</div>
									<input class="form-control" type="hidden" id="profile_img" name="ProfileImage" value={this.state.City} onChange={this.handleInputChange} />
									<span class="text-danger field-validation-valid" data-valmsg-for="ProfileImage" data-valmsg-replace="true"></span>
								</div>
							</div>
						</div>
						*/}


									<div class="row">
										<div class="col-md-12">
											<div class="form-group">
												<label class="control-label" for="Description">Description</label>
												<textarea class="editor form-control" id="Description" name="Description" value={this.state.Description} onChange={this.handleInputChange} ></textarea>
												<span class="text-danger field-validation-valid" data-valmsg-for="Description" data-valmsg-replace="true"></span>
											</div>
										</div>
										<div class="col-md-12">
											<div class="form-group">
												<label class="control-label" for="Biography">Biography</label>
												<textarea class="editor form-control" id="Biography" name="Biography" value={this.state.Biography} onChange={this.handleInputChange} ></textarea>
												<span class="text-danger field-validation-valid" data-valmsg-for="Biography" data-valmsg-replace="true"></span>
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<label class="fa fa-fw fa-phone" for="Phone">Phone</label>
												<input class="form-control" type="text" data-val="true" data-val-required="The Phone field is required." id="Phone" name="Phone" value={this.state.Phone} onChange={this.handleInputChange} readOnly="true" />
												<span class="text-danger field-validation-valid" data-valmsg-for="Phone" data-valmsg-replace="true"></span>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label class="fa fa-fw fa-link" for="Website">Website</label>
												<input class="form-control" type="text" id="Website" name="Website" value={this.state.Website} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="Website" data-valmsg-replace="true"></span>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label class="fa fa-fw fa-envelope" for="Email">Email</label>
												<input class="form-control" type="text" data-val="true" data-val-required="The Email field is required." id="Email" name="Email" value={this.state.Email} onChange={this.handleInputChange} readOnly="true" />
												<span class="text-danger field-validation-valid" data-valmsg-for="Email" data-valmsg-replace="true"></span>
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-md-4">
											<div class="form-group">

												<label class="fa fa-fw fa-facebook" for="FacebookLink">FacebookLink</label>
												<input class="form-control" type="text" id="FacebookLink" name="FacebookLink" value={this.state.FacebookLink} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="FacebookLink" data-valmsg-replace="true"></span>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">

												<label class="fa fa-fw fa-twitter" for="TwitterLink">TwitterLink</label>
												<input class="form-control" type="text" id="TwitterLink" name="TwitterLink" value={this.state.TwitterLink} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="TwitterLink" data-valmsg-replace="true"></span>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">

												<label class="fa fa-fw fa-instagram" for="LinkedLink">Instagram</label>
												<input class="form-control" type="text" id="LinkedLink" name="LinkedLink" value={this.state.LinkedLink} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="LinkedLink" data-valmsg-replace="true"></span>

											</div>
										</div>
									</div>

									<hr />
									<div class="header_box version_2">
										<h2><i class="fa fa-map-marker"></i>Location</h2>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="Address1">Address1</label>
												<input class="form-control" type="text" data-val="true" data-val-required="The Address1 field is required." id="Address1" name="Address1" value={this.state.Address1} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="Address1" data-valmsg-replace="true"></span>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="Address2">Address2</label>
												<input class="form-control" type="text" id="Address2" name="Address2" value={this.state.Address2} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="Address2" data-valmsg-replace="true"></span>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="City">City</label>
												<select id="city_dd" class="form-control " tabindex="-1" aria-hidden="true"></select>
												<input type="hidden" data-val="true" data-val-required="The City field is required." id="City" name="City" value={this.state.City} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="City" data-valmsg-replace="true"></span>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="PostalCode">PostalCode</label>
												<input class="form-control" type="text" data-val="true" data-val-required="The PostalCode field is required." id="PostalCode" name="PostalCode" value={this.state.PostalCode} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="PostalCode" data-valmsg-replace="true"></span>
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="State">State</label>
												<select id="state_dd" class="form-control " d tabindex="-1" aria-hidden="true"></select>
												<input type="hidden" data-val="true" data-val-required="The State field is required." id="State" name="State" value={this.state.State} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="State" data-valmsg-replace="true"></span>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="Country">Country</label>
												<select id="country_dd" class="form-control " tabindex="-1" aria-hidden="true"></select>
												<input type="hidden" data-val="true" data-val-required="The Country field is required." id="Country" name="Country" value={this.state.Country} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="Country" data-valmsg-replace="true"></span>
											</div>
										</div>
									</div>


									<hr />

									<div class="header_box version_2">
										<h2><i class="fa fa-map-marker"></i>Performance Details</h2>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="OffStageMembers">OffStageMembers</label>
												<input class="form-control" type="text" id="OffStageMembers" name="OffStageMembers" value={this.state.OffStageMembers} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="OffStageMembers" data-valmsg-replace="true"></span>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="PerformingMembers">PerformingMembers</label>
												<input class="form-control" type="text" id="PerformingMembers" name="PerformingMembers" value={this.state.PerformingMembers} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="PerformingMembers" data-valmsg-replace="true"></span>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="PerformanceDuration">PerformanceDuration</label>
												<input class="form-control" type="text" id="PerformanceDuration" name="PerformanceDuration" value={this.state.PerformanceDuration} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="PerformanceDuration" data-valmsg-replace="true"></span>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="CanTravel">CanTravel</label>
												<input class="form-control" type="text" id="CanTravel" name="CanTravel" value={this.state.CanTravel} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="CanTravel" data-valmsg-replace="true"></span>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="MinBudget">MinBudget</label>
												<input class="form-control" type="text" data-val="true" data-val-number="The field MinBudget must be a number." id="MinBudget" name="MinBudget" value={this.state.MinBudget} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="MinBudget" data-valmsg-replace="true"></span>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="MaxBudget">MaxBudget</label>
												<input class="form-control" type="text" data-val="true" data-val-number="The field MaxBudget must be a number." id="MaxBudget" name="MaxBudget" value={this.state.MaxBudget} onChange={this.handleInputChange} />
												<span class="text-danger field-validation-valid" data-valmsg-for="MaxBudget" data-valmsg-replace="true"></span>
											</div>
										</div>
									</div>


									<hr />
									<div class="header_box version_2">
										<h2><i class="fa fa-clock-o"></i>Meta Data</h2>
									</div>
									<div class="row">
										<div class="col-md-2">
											<label class="fix_spacing">Skills</label>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<div class="styled-select">
													<select id="skills_dd" multiple="true" tabindex="-1" name="Skills" value={this.state.Skills} onChange={this.handleInputChange}  ></select>
													
												</div>
											</div>
										</div>
										<div class="col-md-2">
											<label class="fix_spacing">Language</label>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<div class="styled-select">
													<select id="language_dd" multiple="true" tabindex="-1" name="Languages" value={this.state.Languages} onChange={this.handleInputChange} ></select>
													
												</div>
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-md-2">
											<label class="fix_spacing">Events</label>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<div class="styled-select">
													<select id="events_dd" multiple="true" tabindex="-1" name="Event" value={this.state.Event} onChange={this.handleInputChange} >

													</select>
													
												</div>
											</div>
										</div>
										<div class="col-md-2">
											<label class="fix_spacing">Tags</label>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<div class="styled-select">
													<select id="tags_dd" multiple="true" tabindex="-1" name="Tags" value={this.state.Tags} onChange={this.handleInputChange}>
													</select>
													
												</div>
											</div>
										</div>
										<div class="col-md-2">
											<label class="fix_spacing">Genres</label>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<div class="styled-select">
													<select id="genre_dd" multiple="true" tabindex="-1" name="Genres" value={this.state.Genres} onChange={this.handleInputChange} >
													</select>
													
												</div>
											</div>
										</div>
									</div>

								</div>



								<div class="text-center form-group"><input type="submit" class="btn_1" value="Submit" id="submit-register" /></div>
							</form>
							
						</div>
					</div>
				</div>


			</>);
		}
		else {
			return (<>
				<div class="container margin_60_40">
					<div class="row justify-content-center">
						<div class="col-lg-4">
							<div class="box_booking_2">

								<div class="main">
									<div id="confirm">
										<div class="icon icon--order-success svg add_bottom_15">
											<img src="img/confirm.svg" />
										</div>
										<h3>Welcome!</h3>
										<p>You wil receive an email confirmation link on your email Id !</p>
									</div>
								</div>
							</div>

						</div>

					</div>

				</div>

			</>);

		}
    }
}
