import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SeoMeta } from './Layout/SeoMeta';
import '../sass/help.scss';
import '../sass/contacts.scss';

export class CmsPage extends Component {
    static displayName = CmsPage.name;


    static propTypes = {
        pageType: PropTypes.string,

    };


    constructor(props) {
        super(props);
        this.state = {
            data: {},
            breadcrums: []
        };
        this.state.breadcrums.push({ title: "Home", url: "/" });
    }


    componentDidMount() {

       

        this.populateWeatherData();
    }

    async populateWeatherData() {
        const response = await fetch('v1/api/cmsPage?pageType=' + this.props.pageType, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        if (data.status) {
            this.setState({ data: data.data, loading: false });
            //debugger
            this.state.breadcrums.push({ title: data.data.title, url: "/"+data.data.pageUrl });

        } else {
            //
            this.setState({ data: null, loading: false });
        }
    }

    static featureImage(data) {
        return (<div class="hero_single inner_pages background-image" style={{ backgroundImage: `url(${data?.featureImage})`}} >
            <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.7)">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-8 col-lg-10 col-md-8">
                            <h1>{data?.title}</h1>
                            <p>{data?.subTitle}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    static pageBody(data) {
        return (
            <>
                <div class="bg_gray">
                    <div class="container margin_60_40" dangerouslySetInnerHTML={{__html: data?.content}}>
                        
                    </div>
                </div>
            </>
        );
    }


    render() {

        let banner = this.state.loading ? "" : CmsPage.featureImage(this.state.data);
        let page = this.state.loading ? "" : CmsPage.pageBody(this.state.data);

        return (
            <>
                <SeoMeta pageType={this.props.pageType} breadcrums={this.state.breadcrums} />
                {banner}
                {page}
            </>
        );
    }
}
