import React, { Component, useState } from 'react';
import '../sass/cat-listing.scss';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import $, { param } from 'jquery';
import { SeoMeta } from './Layout/SeoMeta';


export class PageListing extends Component {

    static propTypes = {
        pageType: PropTypes.string,

    };


    static displayName = PageListing.name;

    static url = "v1/api/customPage/search";
    static author = "";
    static perPage = "20";

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            offset: 0,
            url: "v1/api/customPage/search",
            author: "",
            perPage: "20",
            currentPage: 1,
            searchTerm: "",
            totalPages: 0,
            filters: [],
            selectedFilters: [],
            showCategoryFilter: true,
            showEventFilter: true,
            showLanguageFilter: true,
            showLocationFilter: true,
            pageBaseUrl: '',
            catMsg: '',
            seoPrefix: '',
            breadcrums: [],
        };
        this.state.breadcrums.push({ title: 'Home', url: '/' });
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.handleLikeClick = this.handleLikeClick.bind(this);
    }

    loadArtistsFromServer() {


        $.ajax({
            url: this.state.url,
            data: { pageSize: this.state.perPage, pageNumber: this.state.offset + 1, searchString: this.state.searchTerm, currentFilter: this.state.selectedFilters.join(",") },
            dataType: 'json',
            type: 'GET',

            success: (data) => {
                this.setState({
                    data: data.items,
                    pageCount: data.totalPages,
                    currentPage: data.pageIndex - 1,
                    totalPages: data.totalPages
                });


            },

            error: (xhr, status, err) => {
                console.error(this.state.url, status, err.toString()); // eslint-disable-line
            },
        });
    }


    componentDidMount() {

        const script = document.createElement("script");
        script.src = "/js/sticky_sidebar.min.js";
        script.async = true;
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "/js/specific_listing.min.js";
        script2.async = true;
        document.body.appendChild(script2);

        const query = new URLSearchParams(window.location.search);
        var q = query.get('q');
        var offset = query.get('page');
        var cat = window.location.pathname.split("/").pop();
        var tags = window.location.hash.substr(1);
        var catTitle = ((cat != null) ? cat.replace(/-/g, " ").replace(/_/g, "/").toProperCase() : "");


        if (this.props.pageType == "CategoryList") {

            this.state.selectedFilters.push('category');
            this.state.seoPrefix = catTitle;
            this.state.catMsg = "Categories";
            this.state.breadcrums.push({ title: 'Category', url: '/category' });
           // this.loadByFilter('s__' + cat);
        }

        if (this.props.pageType == "EventsList") {
            this.state.selectedFilters.push('events');
            this.state.showEventFilter = false;
            this.state.seoPrefix = catTitle;
            this.state.catMsg = "Events";
            this.state.breadcrums.push({ title: 'Events', url: '/events' });
           // this.loadByFilter('e__' + cat);
        }

        if (this.props.pageType == "LocationList") {

            this.state.selectedFilters.push('locations');
            this.state.showLocationFilter = false;
            this.state.seoPrefix = catTitle;
            this.state.catMsg = "Locations";
            this.state.breadcrums.push({ title: 'Location', url: '/locations' });
           // this.loadByFilter('lo__' + cat);
        }

        if (this.props.pageType == "PageListing") {

            this.state.catMsg = "Pages";
            this.state.breadcrums.push({ title: 'Pages', url: '/pages' });
        }


       /* if (this.props.pageType == "Language") {

            this.state.showLanguageFilter = false;
            this.state.seoPrefix = catTitle;
            this.state.catMsg = 'For ' + catTitle;
            this.loadByFilter('l__' + cat);
        }

        if (this.props.pageType == "Tag") {

            this.state.showLanguageFilter = false;
            this.state.seoPrefix = tags.replace(/-/g, " ").replace(/_/g, "/");
            this.state.catMsg = 'For #' + tags.replace(/-/g, " ").replace(/_/g, "/");
            this.loadByFilter('t__#' + tags);
        }*/


        this.state.pageBaseUrl = window.location.href.split('?')[0]

        this.setState({ searchTerm: q, offset: (offset != null ? Number(offset) - 1 : 0) }, () => {
            this.loadArtistsFromServer();
        });

        this.GetFilters();

    }

    loadByFilter(filterValue) {
        
        if (filterValue) {
            this.state.selectedFilters.push(filterValue);
        } else {

            const index = this.state.selectedFilters.indexOf(filterValue);
            if (index > -1) {
                this.state.selectedFilters.splice(index, 1);
            }
        }
        this.loadArtistsFromServer(); 
    }
    

    handlePageClick = (data) => {
        console.log('onPageChange', data);
        let selected = data.selected;
        let offset = selected;

        this.setState({ offset: offset }, () => {
            this.loadArtistsFromServer();
            this.updateWindowsUrl();
        });
    };

    handleSearchDebounced = debounce(function () {
        console.log(this.state.searchTerm);
        this.loadArtistsFromServer();
        this.updateWindowsUrl();
    }, 1000);



    handleChange = (e) => {
        this.setState({ searchTerm: e.target.value });
        this.handleSearchDebounced();
    };

    handleChangeFilter = (e) => {
        if (e.target.checked) {

            this.state.selectedFilters.push(e.target.value);


        } else {

            const index = this.state.selectedFilters.indexOf(e.target.value);
            if (index > -1) {
                this.state.selectedFilters.splice(index, 1);
            }
        }
        this.loadArtistsFromServer();
        this.updateWindowsUrl();

        //return false;
    };

    handleLikeClick = async (e, item, idx) => {

        var isLike = this.state.data[idx].isLiked;

        const response = await fetch('v1/api/user/toggleWishlist', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "ArtistId": item.id,
                "isLike": !isLike
            })
        });
        const data = await response.json();
        if (data.status) {

            item.isLiked = data.data;
            this.state.data[idx] = item;
            var data2 = this.state.data;
            this.setState({
                data : data2
            })
           

        } else {
            //
            
        }
    };

    handleFilterSubmit = (e) => {
        this.loadArtistsFromServer();

        e.preventDefault();
        return false;
    };

    updateWindowsUrl = () => {


        var q = this.state.searchTerm;
        var offset = this.state.offset;
        var filters = this.state.selectedFilters;

        var params = [];
        if (q != "" || q != null) {
            params.push("q=" + q);
        }
        if (offset >= 0) {
            params.push("page=" + (Number(offset) + 1));
        }
        if (filters.length > 0) {
            params.push("filters=" + filters.join(","));
        }

        console.log("Params " + params);
        if (params.length > 0) {
            window.history.replaceState(null, "Search & Pagination", this.state.pageBaseUrl + "?" + params.join('&'));
        }


    };


    async GetFilters() {

        $.ajax({
            url: 'v1/api/Filters',
            data: {},
            dataType: 'json',
            type: 'POST',

            success: (data) => {
                this.setState({ filters: data.data, loading: false });
            },

            error: (xhr, status, err) => {
                console.error(this.state.url, status, err.toString()); // eslint-disable-line
            },
        });
    }


    renderCheckboxes(checkboxes, prefix) {
        return checkboxes?.map((item, idx) => { // here
            return (
                <>
                    <li><label class="container_check">{item.name}<input type="checkbox" value={prefix + '__' + item.name} onChange={(event) => this.handleChangeFilter(event)} /><span class="checkmark"></span></label></li>
                </>
            );
        });
    }
     

    renderArtistItems(data) {

        return data?.map((item, idx) => { // here
            return (
                <>
                    <li class="list-group-item">                    
                            
                                <a href={item.url} class="strip_info">
                                    <div class="item_title">
                                        <h5>{item.title}</h5>                                        
                                    </div>
                                </a>
                          
                            {/*<ul>
                            <li><a href="#0" class="tooltip-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Available Appointment"><i class="icon-users"></i></a></li>
                            <li><a href="#0" class="tooltip-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Available Chat"><i class="icon-chat"></i></a></li>
                            <li>
                                <div class="score"><span>Superb<em>350 Reviews</em></span><strong>8.9</strong></div>
                            </li>
                        </ul>*/}
                       </li>                 
                </>
            );
        });
    }


    renderAritstList(data) {

        return (
            <div class="row grid_sidebar">
                <ul class="list-group list-group-flush">
                    {this.renderArtistItems(data)}
                </ul>
            </div>
        );
    }

    renderBreadcrumItems(data) {

        return data?.map((item, idx) => { // here
            return (
                <>
                    <li><a href={item.url}>{item.title}</a></li>
                </>
            );
        });
    }

    renderBreadcrums(data) {

        return (
            <ul class='float-end'>{this.renderBreadcrumItems(data)}</ul>
        );
    }


    render() {



        return (
            <>
                <SeoMeta pageType={this.props.pageType} prefix={this.state.seoPrefix} />
                <div class="filters_full version_2">
                    <div class="container clearfix">
                        <div class="sort_select">
                            <select name="sort" id="sort">
                                <option value="popularity" selected="selected">Sort by Popularity</option>
                                <option value="rating">Sort by Average rating</option>
                                <option value="date">Sort by newness</option>
                            </select>
                        </div>
                      {/*  <a href="javascript:void(0);" class="open_filters btn_filters"><i class="icon_adjust-vert"></i><span>Filters</span></a>*/}

                        <div class="search_bar_list">
                            <input type="text" class="form-control" placeholder="Search..." value={this.state.searchTerm} onChange={this.handleChange} />
                        </div>
                        <a class="btn_search_mobile btn_filters" data-bs-toggle="collapse" href="#collapseSearch"><i class="icon_search"></i></a>
                    </div>



                    <div class="collapse" id="collapseSearch">
                        <div class="search_bar_list">
                            <input type="text" class="form-control" placeholder="Search again..." />
                        </div>
                    </div>

                </div>

                <div class="container margin_30_40">
                    <div class="page_header breadcrumbs">
                            <h1>{this.state.catMsg}</h1>                       
                            {this.renderBreadcrums(this.state.breadcrums)}                       
                    </div>
                    <div class="row">
                       

                        <div class="col-lg-12">

                            {this.renderAritstList(this.state.data)}

                           {/* <div class="pagination_fg">
                                <ReactPaginate
                                    previousLabel="previous"
                                    nextLabel="next"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={this.state.totalPages}
                                    pageRangeDisplayed={4}
                                    marginPagesDisplayed={2}
                                    onPageChange={this.handlePageClick}
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                    // eslint-disable-next-line no-unused-vars
                                    hrefBuilder={(page, pageCount, selected) =>
                                        page >= 1 && page <= pageCount ? `/book-an-artist/${page}` : '#'
                                    }
                                    hrefAllControls
                                    forcePage={this.state.currentPage}
                                    renderOnZeroPageCount={null}
                                    onClick={(clickEvent) => {
                                        console.log('onClick', clickEvent);
                                        // Return false to prevent standard page change,
                                        // return false; // --> Will do nothing.
                                        // return a number to choose the next page,
                                        // return 4; --> Will go to page 5 (index 4)
                                        // return nothing (undefined) to let standard behavior take place.
                                    }}
                                />
                            </div>*/}
                        </div>

                    </div>
                </div>


            </>
        );
    }
}
