import React, { Component } from 'react';
import './NavMenu.css';
import PropTypes from 'prop-types';
import {isMobile} from 'react-device-detect';

export class SubMenuItems extends Component {

    static propTypes = {
        data: PropTypes.object,       
    };


    constructor(props) {
        super(props);

        this.state = {
            data: [],
          
        };

      
    }



    render() {
        let menuItems = this.props.data != undefined ? (Object.keys(this.props.data).map(function (item, index) {
            <li className="submenu">
                <a href="#0" className="show-submenu">{item}</a>               
            </li>
        })): [];
        
        return (
            <>
                {menuItems}
            </>
        );
    }
}

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: {}, loading: true, loadingMenu: true, data: [], isHome: false
        };

        var cat = window.location.pathname;
        this.handleLogout = this.handleLogout.bind(this);
        this.state.isHome = (cat == "" || cat =="/") ? true : false;

    }

    componentDidMount() {
        this.populateUserData();
        this.populateMenu();
        
    }

    async handleLogout(event) {

        debugger;
        localStorage.clear();
        // retrieve all cookies
        var Cookies = document.cookie.split(';');
        // set past expiry to all cookies
        for (var i = 0; i < Cookies.length; i++) {
            document.cookie = Cookies[i] + "=; expires=" + new Date(0).toUTCString();
        }
        const response = await fetch('/v1/api/user/logout', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' }

        });

        window.location.href = '/';
        event.preventDefault();
    }

    UserData(userData) {

        if (userData.status) {

            const dashboard_menu = userData.data.hasDashbordAccess ? (<li><a href="/dashboard" ><i className="icon_cog"></i>Dashboard</a></li>) : "";

            return (
                <ul id="top_menu">
                    <li>
                        <div className="dropdown user clearfix">
                            <a href="#" data-bs-toggle="dropdown">
                                <figure><img src={userData.data.profileImg} alt={userData.data.name} /></figure><span className='user_name'>{userData.data.name || userData.data.email}</span>
                            </a>
                            <div className="dropdown-menu">
                                <div className="dropdown-menu-content">
                                    <ul>
                                        {dashboard_menu}
                                        <li><a href="/profile" ><i className="icon_profile"></i>My Profile</a></li>
                                        <li><a href="/wishlist"><i className="icon_heart"></i>Wishlist</a></li>
                                        <li><a href="/bookingList"><i className="icon_document"></i>Bookings</a></li>
                                        <li><a href="javascript:void(0)" onClick={(event) => this.handleLogout(event)}  ><i className="icon_key"></i>Log out</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </li>
                    <li className="d-xs-none"><a href="/join-for-artist" className="btn_access red">For Artists</a></li>
                </ul>

            );
        } else {

            return (
                <ul id="top_menu">
                    <li className="d-xs-none"><a href="/login?ReturnUrl=%2F" className="btn_access">Log In</a></li>
                    {/*<li className="d-xs-none"><a href="/register?ReturnUrl=%2F" className="btn_access green">For Users</a></li>*/}
                    <li ><a href="/join-for-artist" className="btn_access red">For Artists</a></li>
                </ul>
            );
        }



    }

    SubMenu(data) {
       // console.log("Submenu data" + JSON.stringify(data));
        let menuItems = data?.map(function (item, index) {
            return (

                (item.subMenu.length == 0) ? (<li><a href={item.url} className="show-submenu">{item.title}</a></li>) :            

                            (<li className="submenu">
                                    <a href={isMobile ? 'javascript:void(0)' :item.url} className="show-submenu">{item.title}</a>
                                <ul>
                                        {
                                        item.subMenu.map(function (obj, index) {
                                            return <li><a href={obj.url}>{obj.title}</a></li>;
                                            })
                                        }

                                </ul>
                            </li>)
                )
        });

        return (
            <>
                {menuItems}
            </>
        );
    }

    render() {

        let contents = this.state.loading
            ? <ul id="top_menu"></ul>
            : this.UserData(this.state.userData);

        let subMenu = this.state.loadingMenu ? <li></li>: this.SubMenu(this.state.data);

        return (
            <>

                <header className={this.state.isHome ? "header clearfix element_to_stick" : "header_in clearfix"}>
                    <div className="container">
                        <div id="logo">
                            <a href="/">
                                <img src="img/logo/logo_spotdaile.svg" height="35" alt="" />
                            </a>
                        </div>

                        {contents}

                        <a href="#0" className="open_close">
                            <i className="icon_menu"></i><span>Menu</span>
                        </a>
                        <nav className="main-menu">
                            <div id="header_menu">
                                <a href="#0" className="open_close">
                                    <i className="icon_close"></i><span>Menu</span>
                                </a>
                                <a href="/"><img src="img/logo/logo_spotdaile.svg" height="35" alt="" /></a>
                            </div>
                            <ul>
                                <li>
                                    <a href="/" >Home</a>
                                </li>
                                {subMenu}
                               <li><a href="/contact-us">Contact Us</a></li>
                              
                            </ul>
                        </nav>
                    </div>
                </header>

            </>
        );
    }


    async populateUserData() {
        const response = await fetch('/v1/api/User', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' }
            /*body: JSON.stringify({				
            })*/
        });
        const data = await response.json();
        

        this.setState({ userData: data, loading: false });
    }

    async populateMenu() {
        const response = await fetch('/v1/api/SubMenu', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' }
            /*body: JSON.stringify({				
            })*/
        });
        const data = await response.json();

        this.setState({
            data: data.data, loadingMenu: false
           
        });
       
    }
   
}
